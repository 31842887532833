import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import StaticBG from "../../../components/BG/StaticBG";
import NavBar from "../../../components/NavBar/NavBar";
import PageHeader from "../../../components/PageHeader";
import Categories from "./components/Categories";
import Loading from "../../../components/Loading";
import { API_URL } from "../../../data/API";
import NeedsAuth from "../../../components/NeedsAuth";
import PrimaryBtn from "../../../components/PrimaryBtn";
import Dialog from "../../../components/Dialog";
import CreateNewProject from "./components/CreateNewProject";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";

const Projects = () => {
  const { id } = useParams();
  const [editDialogShow, setEditDialogShow] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const { auth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/category/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const deleteProject = async (id: number) => {
    fetch(`${API_URL}/project/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth?.accessToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("Project deleted successfully");
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const [idxToDelete, setIdxToDelete] = useState<number>(0);
  const getIndex = (idx: number) => {
    setIdxToDelete(idx);
  };

  const [firstRender, setFirstRender] = useState(true);
  const [secondRender, setSecondRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else if (secondRender) {
      setSecondRender(false);
    } else if (idxToDelete !== 0) {
      deleteProject(idxToDelete);
    }
  }, [firstRender, idxToDelete, secondRender]);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Box sx={{ width: "95%", margin: "2rem auto 1rem" }}>
        <PageHeader Header={data.category.name} />
        <NeedsAuth Allowed={["admin"]}>
          <PrimaryBtn
            title="Add New Project"
            type="button"
            onClick={() => setEditDialogShow(!editDialogShow)}
          />
          <Dialog show={editDialogShow}>
            <CreateNewProject id={id ? id : ""} />
          </Dialog>
        </NeedsAuth>
        <Categories
          data={data.category.projects}
          // deleteProject={() => deleteProject(idxToDelete)}
          getIndex={getIndex}
        />
      </Box>
    </>
  );
};

export default Projects;

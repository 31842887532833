import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StaticBG from "../../../../components/BG/StaticBG";
import NavBar from "../../../../components/NavBar/NavBar";
import { API_URL } from "../../../../data/API";
import useAuth from "../../../../hooks/useAuth";
import NeedsAuth from "../../../../components/NeedsAuth";
import Loading from "../../../../components/Loading";
import AddProjectSection from "../../../../components/sections/AddProjectSection";
import Sections from "./sections/Sections";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CreateNewProject from "./CreateNewProject";
import Dialog from "../../../../components/Dialog";
const ProjectPage = () => {
  const modifyArray = (
    arr: { type: string; urls: string[] }[],
    index: number,
    obj: { type: string; urls: string[] }
  ) => {
    const newArray = [...arr];
    newArray.splice(index, 0, obj);
    return newArray;
  };

  const { id } = useParams<string>();
  const { auth } = useAuth();
  const [editDialogShow, setEditDialogShow] = useState<boolean>(false);

  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const getPushIndex = (id: number) => {
    setCurrentIdx(id);
  };

  const getData = (data: { type: string; urls: string[] }) => {
    updateProject(modifyArray(mediaData, currentIdx, data));
  };

  const [data, setData] = useState<any>(null);
  const [mediaData, setMediaData] = useState<
    { type: string; urls: string[] }[]
  >([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/project/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);

        setMediaData(result.project.media);
      } catch (error: any) {
        setError(error.message || "An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateProject = async (array: { type: string; urls: string[] }[]) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/project/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify({ media: array }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
      window.location.reload();
    } catch (error: any) {
      setError(error.message || "An error occurred while fetching the data.");
    }
  };
  function deleteElementAtIndex(
    array: { type: string; urls: string[] }[],
    indexToDelete: number
  ) {
    if (indexToDelete >= 0 && indexToDelete < array.length) {
      array.splice(indexToDelete, 1);
      return array;
    } else {
      console.log("Invalid index specified.");
      return array;
    }
  }
  const getCategoriesNames = (arr: {}[], wanted: string) => {
    const categoriesNames: any[] = [];
    if (arr?.length > 0) {
      arr.map((ele: any) => {
        const value = wanted === "id" ? parseInt(ele[wanted]) : ele[wanted];
        categoriesNames.push(value);
      });
    }
    return categoriesNames;
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <NeedsAuth Allowed={["admin"]}>
        <Dialog show={editDialogShow}>
          <CreateNewProject
            edit
            init={{
              name: data.project.name,
              description: data.project.description,
              memberIds: getCategoriesNames(data.project.members, "id"),
              atHome: data.project.atHome,
            }}
            updateId={id}
          />
        </Dialog>
      </NeedsAuth>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Box sx={{ width: "90%", margin: "2rem auto 1rem" }}>
        <Box sx={{ width: "100%", position: "relative" }}>
          <NeedsAuth Allowed={["admin"]}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "1rem 2rem",
                backgroundColor: "red",
                color: "white",
                borderRadius: "10px",
                position: "absolute",
                right: "0",
                cursor: "pointer",
              }}
              onClick={() => setEditDialogShow(!editDialogShow)}
            >
              <Typography sx={{ marginRight: "1rem" }}>
                Edit Project Data
              </Typography>
              <EditRoundedIcon />
            </Box>
          </NeedsAuth>

          <Box
            sx={{ width: "100%", textAlign: "center", marginBottom: "1rem" }}
          >
            <Typography sx={{ color: "white", fontSize: "2rem" }}>
              {data?.project?.name}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <NeedsAuth Allowed={["admin"]}>
              <AddProjectSection
                projectID={id ? id : ""}
                onClick={() => getPushIndex(0)}
                getData={getData}
              />
            </NeedsAuth>
            {data?.project?.media.map(
              (media: { type: string; urls: string[] }, idx: number) => (
                <Sections
                  obj={media}
                  getData={getData}
                  id={id ? id : ""}
                  deleteSections={() =>
                    updateProject(deleteElementAtIndex(mediaData, idx))
                  }
                  onClick={() => getPushIndex(idx + 1)}
                />
              )
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProjectPage;

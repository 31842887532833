import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function ImgList({ imgs, fullHeight, cols }: { imgs: string[], fullHeight?: boolean, cols?: number }) {
    return (
        <Box sx={{ height: fullHeight ? '100%' : 430, overflowY: 'auto' }}>
            <ImageList variant="masonry" cols={cols ? cols : 3} gap={8} sx={{ marginTop: '0', marginLeft: '0.5rem' }}>
                {imgs.map((item) => (
                    <ImageListItem key={item} >
                        <img
                            srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item}?w=248&fit=crop&auto=format`}
                            alt={item}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}

import React, { ChangeEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import PrimaryBtn from "../../../../components/PrimaryBtn";
import { API_URL } from "../../../../data/API";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
const S3_BUCKET = "hplus-media-bucket";
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const AddMemberForm = ({
  init,
  edit,
  memberID,
}: {
  init?: any;
  edit?: boolean;
  memberID?: string;
}) => {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleFileInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
    uploadFile(e.target.files[0]);
  };

  const [UUID, setUUID] = useState<string>("");

  const uploadFile = (file: any) => {
    const uuidString = uuidv4();
    const fileExtension = file.name.split(".").pop() || "";
    const newFilename = `${uuidString}.${fileExtension}`;
    setUUID(newFilename);
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: newFilename,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  const deleteImageFromBucket = (key: string) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
    };

    myBucket.deleteObject(params, (err, data) => {
      if (err) {
        console.error("Error deleting image:", err);
      } else {
        console.log("Image deleted successfully");
      }
    });
  };

  const initialValues = {
    name: "",
    role: "",
    teamName: "",
    description: "",
  };
  const AddPatientSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    role: Yup.string().required("Role is Required"),
    teamName: Yup.string().required("Team Name is Required"),
    // description: Yup.string().required("Message is Required"),
  });
  const { auth } = useAuth();

  const addMember = async (values: {
    name: string;
    role: string;
    teamName: string;
    description: string;
  }) => {
    try {
      const url = `${API_URL}/member`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify(
          UUID !== "" ? { ...values, pictureUrl: UUID } : values
        ),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("API response:", data);
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const editMember = async (values: {
    name: string;
    role: string;
    teamName: string;
    description: string;
  }) => {
    try {
      setLoading(true);

      const response = await fetch(`${API_URL}/member/${memberID}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify(
          UUID !== "" ? { ...values, pictureUrl: UUID } : values
        ),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (error: any) {
      setError(error.message || "An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_S3_BUCKET;
    if (init.pictureUrl !== null) {
      s3.getSignedUrl(
        "getObject",
        { Bucket: bucketName, Key: init.pictureUrl, Expires: 60 },
        (err, url) => {
          if (err) {
            console.error("Error generating signed URL:", err);
            return;
          }
          setImageUrl(url);
          console.log(url)
        }
      );
    }
  }, []);

  return (
    <Formik
      initialValues={init ? init : initialValues}
      validationSchema={AddPatientSchema}
      onSubmit={(values: any, { resetForm }: { resetForm: any }) => {
        if (edit) {
          editMember(values);
          navigate("/team");
          resetForm();
        } else {
          addMember(values);
          navigate("/team");
          resetForm();
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }: {
        values: any;
        touched: any;
        errors: any;
        handleChange: any;
        handleBlur: any;
        handleSubmit: any;
      }) => (
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box sx={{ maxWidth: "12rem" }}>
              <Box
                component="img"
                src={
                  selectedFile !== null
                    ? URL.createObjectURL(selectedFile)
                    : imageUrl
                      ? imageUrl
                      : "https://i.postimg.cc/y6jCwkfY/user.jpg"
                }
                alt="Selected"
                sx={{ maxWidth: "100%" }}
                loading="lazy"
              />
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: "100%",
                  borderRadius: "0",
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                {progress > 0 ? (
                  progress === 100 ? (
                    <CheckRoundedIcon />
                  ) : (
                    progress
                  )
                ) : (
                  "Upload Photo"
                )}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileInput}
                />
              </Button>
            </Box>
            <Box>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField
                    // withoutLabel
                    enable
                    isRequired
                    name="name"
                    label="Name"
                    value={values.name}
                    error={errors.name}
                    touched={touched.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField
                    // withoutLabel
                    enable
                    isRequired
                    name="role"
                    label="Role"
                    value={values.role}
                    error={errors.role}
                    touched={touched.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField
                    // withoutLabel
                    enable
                    isRequired
                    name="teamName"
                    label="Team Name"
                    value={values.teamName}
                    error={errors.teamName}
                    touched={touched.teamName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField
                    // withoutLabel
                    enable
                    isRequired
                    name="description"
                    label="Description"
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ marginTop: "0rem" }}>
            <PrimaryBtn title="Save" />
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default AddMemberForm;

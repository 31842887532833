import { Box } from "@mui/material";
import React from "react";

const TextSecton = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        color: "white",
        fontWeight: "600",
        fontSize: "4rem",
        textAlign: "center",
      }}
    >
      {text}
    </Box>
  );
};

export default TextSecton;

import React, { ReactNode } from "react";
import useAuth from "../hooks/useAuth";

const NeedsAuth = ({
  children,
  Allowed,
}: {
  children: ReactNode;
  Allowed: string[];
}) => {
  const { auth } = useAuth();

  return <>{Allowed.includes(auth?.role?.toLowerCase()) ? children : null}</>;
};

export default NeedsAuth;

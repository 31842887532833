import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import Loading from '../../../../components/Loading';
const VideoPlayer = () => {

    const videos =
        [
            { link: "https://drive.google.com/file/d/1vm2OybS5ZCp9yZ-qhx543MGI8A2bfkyU/preview", imgSrc: "https://i.postimg.cc/yYZfzTkF/mma.jpg" },
            { link: "https://drive.google.com/file/d/1XeRcOws7--jQjxeHJJp_nKkph6IakbJQ/preview", imgSrc: "https://i.postimg.cc/d3T9wqtq/evo.jpg" },
            { link: "https://drive.google.com/file/d/1AIkiA18cu3SMEOWEdXx4t1ChDQKcwly3/preview", imgSrc: "https://i.postimg.cc/C1R2npL2/hamza.jpg" },
            { link: "https://drive.google.com/file/d/1wCeTIBDIH9se1EbqseBNJQ_f0mgcrEhi/preview", imgSrc: "https://i.postimg.cc/NM2Nf8dq/edlf.jpg" },
            { link: "https://drive.google.com/file/d/1MD8YhNe0kpRGWzBlrN-zkbRli4T_MKAA/preview", imgSrc: "https://i.postimg.cc/Mpst734J/swiftact.jpg" }
        ]
    const [currentVideo, setCurrentVideo] = useState<{ link: string, imgSrc: string }>({ link: "https://drive.google.com/file/d/1MD8YhNe0kpRGWzBlrN-zkbRli4T_MKAA/preview", imgSrc: "https://i.postimg.cc/Mpst734J/swiftact.jpg" })
    const [playingVideo, setPlayingVideo] = useState<boolean>(true)
    const [logoSize, setLogoSize] = useState<boolean>(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLogoSize(!logoSize);
        }, 500);

        return () => {
            clearInterval(intervalId);
        };
    }, [logoSize]);

    return (
        <Box
            sx={{
                height: '85vh',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                boxSizing: "border-box",
                position: "relative",
                overflow: 'hidden',
                width: '99.4vw',
                left: 'calc(-49.7vw + 50%)',
            }}
        >
            <Box component='img' src={currentVideo.imgSrc} sx={{
                position: 'absolute',
                zIndex: '-1',
                width: '100%',
                filter: 'blur(10px)',
                opacity: 0.5,
            }} />
            <Grid container spacing={2} sx={{
                width: {
                    lg: "72%",
                    md: "72%",
                    sm: "90%",
                    xs: "100%",
                },
            }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={{
                        width: {
                            lg: "60%",
                            md: "60%",
                            sm: "100%",
                            xs: "100%",
                        }, margin: "0 auto",
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '10px',
                        cursor: 'pointer',
                    }}

                    >
                        <Box
                            className="overlay"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#00000060',
                                width: '110%',
                                height: '100%',
                                zIndex: 10,
                                transition: '0.3s',
                                pointerEvents: 'none',
                                opacity: playingVideo ? 0 : 1,

                            }}>
                            <Box
                                component="img"
                                src="https://i.postimg.cc/brmRcPLT/logo.png"
                                alt=""
                                sx={{
                                    width: "8rem",
                                    display: { xs: "none", md: "flex" },
                                    transition: "0.5s ease-in-out",
                                    transform: `${logoSize ? "scale(1.2)" : "scale(1)"}`,
                                }}
                            ></Box>
                        </Box>
                        <Box component='img' src={currentVideo.imgSrc} sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            pointerEvents: 'none',
                            transition: '0.3s',
                            opacity: playingVideo ? 0 : 1,
                        }} />
                        <iframe
                            title="test"
                            src={currentVideo.link}
                            style={{ width: '100%', aspectRatio: '640 / 360', borderRadius: '10px' }}

                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        >

                        </iframe>
                    </Box>
                </Grid>
                {videos.map((video: { link: string, imgSrc: string }) => (
                    video.link !== currentVideo.link ? (

                        <Grid item lg={3} md={3} sm={3} xs={6} sx={{
                            cursor: 'pointer',
                            '&:hover .overlay': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            },
                            '&:hover .icon': {
                                opacity: 1,
                            },
                        }} onClick={() => (
                            setCurrentVideo(video),
                            setPlayingVideo(false),
                            setTimeout(() => {
                                setPlayingVideo(true)
                            }, 1500))
                        }>
                            <Box sx={{ pointerEvents: 'none', position: 'relative', overflow: 'hidden', borderRadius: '10px' }}>

                                <Box
                                    className="overlay"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#00000040',
                                        width: '110%',
                                        height: '100%',
                                        zIndex: 10,
                                        transition: '0.3s',
                                    }}>
                                    <PlayCircleOutlineRoundedIcon
                                        className="icon"
                                        sx={{
                                            opacity: 1,
                                            transition: '0.3s',
                                            fontSize: '3rem',
                                        }} />
                                </Box>
                                <Box component='img' src={video.imgSrc} sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                }} />
                                <iframe
                                    title="test"

                                    src={video.link}
                                    style={{ width: '100%', aspectRatio: '640 / 360', borderRadius: '10px' }}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                >
                                </iframe>
                            </Box>
                        </Grid>
                    ) : null
                ))}
            </Grid>

        </Box >
    )
}

export default VideoPlayer
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
interface LinkCardProps {
  imgSrc: string
  link: string
  title: string
}
const LinkCard = ({ imgSrc, link, title }: LinkCardProps) => {
  const [isHovered, setIsHovered] = useState(false);



  return (
    <Link to={link} target="_blank" rel="noopener noreferrer">
      <Box sx={{ width: '100%', cursor: 'pointer', position: 'relative', mb: 4 }}>
        <Box component='img' src={imgSrc} alt='' sx={{
          width: '100%', zIndex: '1'
        }} />
        <Box sx={{
          position: 'absolute', top: '0', width: '100%', height: '100%', backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
          // opacity: isHovered ? 0.5 : 0,
          transition: 'background-color 0.3s ease-in-out',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '2'

        }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <OpenInNewIcon sx={{
            color: 'white',
            transition: 'opacity 0.3s ease-in-out',
            opacity: isHovered ? 1 : 0,
            fontSize: '2rem'
          }} />
        </Box>
        <Box>
          <Typography sx={{
            width: '100%',
            color: 'white',
            textAlign: 'center',
            backgroundColor: '#111',
            padding: '1rem',
            boxSizing: 'border-box',
            position: 'absolute',
            transition: 'transform 0.3s ease-in-out',
            transform: isHovered ? 'translateY(0%)' : 'translateY(-100%)',
            zIndex: '-1'
          }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}

export default LinkCard
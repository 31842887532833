import React from "react";
import Welcome from "./components/Welcome";
import NavBar from "../../../components/NavBar/NavBar";
import BG from "../../../components/BG/BG";
import StaticBG from "../../../components/BG/StaticBG";
import { Box, Button, Typography } from "@mui/material";
import HomeCategories from "./components/categories/HomeCategories";
import HomeMembers from "./components/members/HomeMembers";
import VideoPlayer from "./components/VideoPlayer";
import ImgList from "./components/img-list/ImgList";
import PageHeader from "../../../components/PageHeader";
import Clients from "./components/clients/Clients";
import HomeCount from "./components/HomeCount";
import AnimatedTitle from "../../../components/AnimatedTitle";
import Drone from "./components/drone/Drone";
import Bio from "./components/Bio";
import { Link } from "react-router-dom";


const Home = () => {
  return (
    <Box >
      {/* <NavBar /> */}
      {/* <BG />
      <StaticBG /> */}
      <Welcome />
      <Box sx={{
        width: {
          lg: "80%",
          md: "90%",
          sm: "90%",
          xs: "90%",
        }, margin: '0 auto'
      }}>
        <AnimatedTitle title="Highlights" />
        <VideoPlayer />
      </Box>
      <Box sx={{
        width: {
          lg: "80%",
          md: "90%",
          sm: "90%",
          xs: "90%",
        }, margin: "0 auto"
      }}>

        <AnimatedTitle title="Our Mission" />
      </Box>
      <Bio />
      <HomeCount />
      <Box sx={{
        width: '100%', zIndex: "100", margin: "0 auto"
      }}>
        <Box sx={{
          width: {
            lg: "80%",
            md: "90%",
            sm: "90%",
            xs: "90%",
          }, margin: "0 auto"
        }}>

          <AnimatedTitle title="Our Departments" />
        </Box>
        <HomeCategories />
      </Box>
      {/* <Drone src="https://i.postimg.cc/RFW4SsZH/Remote-Media-File-6553884-0-2022-07-23-18-57-36.jpg" alt="test"/> */}
      <Box sx={{
        width: {
          lg: "80%",
          md: "90%",
          sm: "90%",
          xs: "90%",
        }, margin: "1rem auto"
      }}
      >
        <PageHeader Header="Document Every Momoment" />
        <ImgList fullHeight cols={3} imgs={
          [
            'https://i.postimg.cc/X7RMwMrb/Men-1.gif',
            'https://i.postimg.cc/nLx6PvKS/Men-1-3.gif',
            'https://i.postimg.cc/FRT5c7LW/Men-1-4.gif',
            // 'https://i.postimg.cc/GmDCNLWt/Men-1-1.gif',
            'https://i.postimg.cc/L6YKY46D/Men-1-2.gif',
            'https://i.postimg.cc/jq1BbVQ7/Men-1-5.gif',
            'https://i.postimg.cc/Y9nZ0Z5T/Men-1-7.gif'
          ]
        } />
      </Box>
      <Box sx={{
        width: {
          lg: "80%",
          md: "90%",
          sm: "90%",
          xs: "90%",
        }, margin: "1rem auto"
      }}>
        <PageHeader Header="Our Clients" />
        <Clients />
      </Box>
      <Box sx={{
        width: {
          lg: "80%",
          md: "90%",
          sm: "90%",
          xs: "90%",
        }, margin: "1rem auto"
      }}>
        <PageHeader Header="Meet Our Team" />
        <HomeMembers />
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        backgroundColor: "#1f1f1f90",
        padding: "1rem 2rem",
        boxSizing: "border-box",
        backdropFilter: "blur(5px)",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Box sx={{
          display: "flex", justifyContent: "center",
          alignItems: "center",
        }}>

          <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
            height: "2rem", mr: 2
          }} />
          <Typography sx={{ color: 'white', mr: 2, fontSize: '1.5rem' }}>
            The cameras are set, the team is excited, and the drone is flying, waiting for your call.
          </Typography>
        </Box>
        <Link
          to='/contactus'
          style={{ textDecoration: "none" }}
        >
          <Box sx={{
            width: "100%",
            height: "1px",
            backgroundColor: 'white',
            transform: "scaleX(0)",
            transition: "0.3s",
            '&:hover': {
              transform: "scaleX(1)",
            }
          }} />
          <Button

            sx={{
              my: 2,
              color: "white",
              display: "block",
              background: "linear-gradient(45deg, rgb(146 10 10 / 18%) 20%, rgb(146 10 10) )",
              fontSize: {
                lg: "1.5rem",
                md: "1.5rem",
                sm: "1.5rem",
                xs: "1.5rem",
              },

            }}
          >
            KEEP IN TOUCH
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Home;

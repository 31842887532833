import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import PageHeader from '../../../../components/PageHeader'

const bio: string = "H plus is one of the top multimedia companies that elevate your media to heights. We specialize in  delivering 360 media production solutions."

const SummaryCards = () => {
    return (
        <Grid container spacing={2} sx={{ marginBottom: '1rem', justifyContent: 'center' }}>
            <Grid item lg={9} md={11} sm={12} xs={12} sx={{ justifyContent: 'center' }} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                    },

                    justifyContent: 'center', alignItems: 'center',
                }}>

                    <Box sx={{
                        backgroundColor: "#1f1f1f90",
                        display: "flex", flexDirection: {
                            lg: "row",
                            md: "row",
                            sm: "column",
                            xs: "column",
                        },
                        width: "100%",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "3rem 2rem",
                        textAlign: "center",
                        border: "1px solid black",
                        boxShadow: "0 0 20px black",
                        boxSizing: "border-box",
                        borderRadius: "10px",
                        backdropFilter: "blur(5px)",
                        marginLeft: {
                            lg: "-2.5rem",
                            md: "-2.5rem",
                            sm: "0",
                            xs: "0",
                        },
                    }}>
                        <Box component='img' src='https://i.postimg.cc/vZhhz1rw/04.png' sx={{
                            position: 'absolute',
                            bottom: "0",
                            right: "0",
                            height: "80%",
                            zIndex: '-1'
                        }} />
                        <PageHeader Header='Our Mission' />

                        <Typography sx={{
                            color: 'white', textAlign: {
                                lg: "left",
                                md: "left",
                                sm: "center",
                                xs: "center",
                            },
                        }}>{bio}</Typography>
                    </Box>
                </Box>

            </Grid>

        </Grid>
    )
}

export default SummaryCards
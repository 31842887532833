import { Box } from "@mui/material";
import React from "react";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

const TeamMemberCardImage = ({ src, team }: { src: string, team: string }) => {
  return (
    <Box
      sx={{
        color: "white",
        maxWidth: "100%",
        borderRadius: "10px",
        aspectRatio: "1/1",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* <Box
        sx={{
          position: "absolute",
          width: "100%",
          borderRadius: "10px",
          aspectRatio: "1/1",
          background:
            "linear-gradient(to top left, #ff0000 0%, transparent 60%)",
          zIndex: "1",
          filter: "opacity(0.2)",
          transition: "0.3s ease-in-out",
          "&:hover": {
            filter: "opacity(0.8)",
          },
        }}
      /> */}
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          right: "2rem",
          backgroundColor: team.toLowerCase() === "management" ? "#0000ff50" : (team.toLowerCase() === "graphic design" ? "#be079a99" : (team.toLowerCase() === "business development" ? "#00ffff50" : "#ff000099")),
          padding: "0.5rem",
          boxSizing: "border-box",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          zIndex: "1",
          transition: "0.3s ease-in-out",
        }}
      >
        {team.toLowerCase() === "management" ? <GroupsRoundedIcon /> : (team.toLowerCase() === "graphic design" ? <DesignServicesRoundedIcon /> : (team.toLowerCase() === "business development" ? <DomainAddIcon /> : <VideocamRoundedIcon />))}
      </Box>
      <Box
        component="img"
        src={src}
        loading="lazy"
        sx={{
          color: "white",
          maxWidth: "100%",
          borderRadius: "10px",
          position: "relative",
          filter: "grayscale(100%)",
        }}
      ></Box>
    </Box>
  );
};

export default TeamMemberCardImage;

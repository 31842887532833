import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TeamMemberCard from "./TeamMemberCard";
import NeedsAuth from "../../../../components/NeedsAuth";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading";
import { API_URL } from "../../../../data/API";
import AWS from "aws-sdk";
const MembersContainer = () => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/member`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error: any) {
        setError(error.message || "An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  
  
 
  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2}>
      <NeedsAuth Allowed={["admin"]}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Link to="add-member" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  color: "white",
                  backgroundColor: "red",
                  padding: "1rem 2rem",
                  boxSizing: "border-box",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography>Add New Team Member</Typography>
                <AddCircleOutlineRoundedIcon />
              </Box>
            </Link>
          </Grid>
        </Grid>
      </NeedsAuth>
      {data?.members?.map((member: any, idx: number) => (
        <Grid item lg={3} md={4} sm={6} xs={12} key={idx}>
          <TeamMemberCard
            UUID={member.pictureUrl}
            id={member.id}
            name={member.name}
            pos={member.role}
            team={member.teamName}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MembersContainer;

import React from 'react'

const LogoPart1 = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 355 1000" style={{
            width: '100%',
            height: '100%',
        }}>
            <defs>
                <style>
                    {` .cls-1 {
        fill: #fff;
        stroke-width: 0px;
      }`}
                </style>
            </defs>
            <path className="cls-1" d="M339,0v1000H56.8c-28.2,0-51.1-22.9-51.1-51.1V51.1C5.7,22.9,28.6,0,56.8,0h282.2,0Z" />
        </svg>
    )
}

export default LogoPart1
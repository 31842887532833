import React from 'react'
import { TypeAnimation } from 'react-type-animation';

const TypingAnimation = ({ text, watingTime }: { text: string[], watingTime?: number }) => {

    const convertArray = (text: string[], watingTime?: number): (string | number)[] => {
        const convertedArray: (string | number)[] = [];
        text.forEach(item => {
            convertedArray.push(`We offer ${item}`);
            // convertedArray.push(item);
            convertedArray.push(watingTime ? watingTime : 1000);
        });

        return convertedArray;
    };

    return (
        <TypeAnimation
            sequence={convertArray(text, watingTime)}
            wrapper="span"
            speed={50}
            style={{
                fontSize: "inherit",
                fontWeight: "400", display: 'inline-block'
            }}
            repeat={Infinity}
        />
    )
}

export default TypingAnimation
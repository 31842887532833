import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useRef, useState } from "react";

const Dialog = ({ children, show }: { children: ReactNode; show: boolean }) => {
  const [display, setDisplay] = useState<boolean>(false);
  const prevShowRef: any = useRef();

  useEffect(() => {
    if (prevShowRef.current === undefined) {
      prevShowRef.current = show;
      return;
    }
    if (prevShowRef.current !== show) {
      setDisplay(true);
      prevShowRef.current = show;
    }
  }, [show]);

  return (
    <Box sx={{ zIndex: "4", display: `${display ? "block" : "none"}` }}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#00000050",
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "5",

        }}
        onClick={() => setDisplay(false)}
      ></Box>
      <Box
        sx={{
          zIndex: "5",
          width: "30rem",
          backgroundColor: "#1f1f1f",
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          borderRadius: "10px",
          padding: "2rem",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Dialog;

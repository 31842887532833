import React, { useEffect, useState } from "react";
import { API_URL } from "../../../../../data/API";
import Loading from "../../../../../components/Loading";
import CategoryCard from "./CategoryCard";
import StaticBG from "../../../../../components/BG/StaticBG";
import NeedsAuth from "../../../../../components/NeedsAuth";
import AddCategory from "./AddCategory";
import { Box } from "@mui/material";
import CircularRepeatText from "../../../../../components/CircularRepeatText";

const HomeCategories = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/category/home`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result.categories);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <Box sx={{ zIndex: "1", width: "100%", margin: "2rem auto", }} >
      {data.map((Category: any, idx: number) => (
        <>
          {Category.name !== 'Drone Operating' ?
            <><CircularRepeatText sentence={Category.description} /><Box sx={{
              width: {
                lg: "72%",
                md: "90%",
                sm: "90%",
                xs: "90%",
              }, margin: "0 auto"
            }}>
              <CategoryCard Category={Category} key={idx} />
            </Box></>
            : null}

        </>
      ))}
      <NeedsAuth Allowed={["admin"]}>
        <AddCategory />
      </NeedsAuth>
    </Box>
  );
};

export default HomeCategories;

import { Box, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

interface CountProps {
    initialValue: number;
    finalValue: number;
    duration: number;
    text?: string;
}

const Count: React.FC<CountProps> = ({ initialValue, finalValue, duration, text }) => {
    const [count, setCount] = useState(initialValue);
    const step = (finalValue - initialValue) / (duration / 1000); // calculate step value per second
    const countRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let startTime = Date.now();
        let observer: IntersectionObserver | null = null;

        const updateCount = () => {
            const elapsed = Date.now() - startTime;
            if (elapsed < duration) {
                setCount(initialValue + step * (elapsed / 1000));
                requestAnimationFrame(updateCount);
            } else {
                setCount(finalValue); // ensure final value is reached exactly
            }
        };

        const startCounting = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio > 0) {
                    startTime = Date.now();
                    observer?.disconnect(); // Stop observing once the element is visible
                    requestAnimationFrame(updateCount);
                }
            });
        };

        observer = new IntersectionObserver(startCounting, {
            threshold: 0.5, // Trigger when at least 50% of the element is visible
        });

        if (countRef.current) {
            observer.observe(countRef.current);
        }

        return () => {
            observer?.disconnect(); // Cleanup observer on unmount
        };
    }, [initialValue, finalValue, duration, step]);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '30vh' }}>
            <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
                height: "4rem",
                marginRight: '2rem'
            }} />
            <Box sx={{
                height: '100%', width: `${finalValue.toString().length * 3}rem`,
                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column', position: "relative",

            }}>
                {text ? <Typography sx={{

                    color: 'white',
                    fontSize: '1.3rem',
                    width: 'max-content',
                    position: "absolute",
                    bottom: {
                        lg: "2rem",
                        md: "2rem",
                        sm: "3.5rem",
                        xs: "3.5rem",
                      },
                    right:  {
                        lg: "-20%",
                        md: "-20%",
                        sm: "-1.5rem",
                        xs: "-1.5rem",
                      },
                    transform: "translate(-0%,-50%)",
                    background: 'linear-gradient(to bottom right, #DC2D02, #160259)',
                    padding: "0.2rem 1.5rem",
                    boxSizing: 'border-box',
                    zIndex: '1000',
                    borderRadius: '5px'
                }}>
                    {text}
                </Typography> : null}
                <Typography sx={{
                    color: "white", fontSize: {
                        lg: "6rem",
                        md: "3rem",
                        sm: "5rem",
                        xs: "5rem",
                    },
                    fontWeight: "bold",
                    textAlign: "left",
                    // WebkitTextFillColor: 'transparent',
                    // WebkitTextStroke: "1px red", // WebkitTextStroke for Safari/Chrome
                    // textStroke: "1px red", // text-stroke for other browsers
                }} ref={countRef}>
                    {count.toFixed(0)}

                </Typography>
            </Box>
        </Box>
    )
        ;
};

export default Count;

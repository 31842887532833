import { Box, Grid } from "@mui/material";
import React from "react";
import ThumbnailProjectCard from "./Cards/ThumbnailProjectCard";
import { Link } from "react-router-dom";
import NeedsAuth from "../../../../components/NeedsAuth";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const Categories = ({
  data,
  // deleteProject,
  getIndex,
}: {
  data: any[];
  // deleteProject: any;
  getIndex: (idx: number) => void;
}) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      {data.map((ele: any, idx: number) => (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          sx={{ minHeight: "100%", position: "relative" }}
          key={idx}
        >
          <NeedsAuth Allowed={["admin"]}>
            <Box
              sx={{
                padding: "0.5rem",
                aspectRatio: "1/1",
                borderRadius: "5px",
                backgroundColor: "Red",
                position: "absolute",
                top: "0",
                right: "0",
                cursor: "pointer",
                zIndex: "4",
                margin: "1rem 0.5rem",
              }}
              onClick={() => {
                // deleteProject();
                getIndex(ele.id);
              }}
            >
              <DeleteRoundedIcon sx={{ color: "white" }} />
            </Box>
          </NeedsAuth>
          <Link to={`/projects/${ele.id}`}>
            <ThumbnailProjectCard
              projectImageSrc={
                ele.pictureUrl !== null
                  ? ele.pictureUrl
                  : "https://i.postimg.cc/QtB5JtTk/Untitled-1.jpg"
              }
              projectName={ele.name}
              // deleteProject={deleteProject}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Categories;

import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Box } from "@mui/system";
import { FormControl, FormHelperText, Typography } from "@mui/material";
export interface CustomTextFieldProps {
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: any) => void;
  name: string;
  label: string;
  error: string | undefined;
  touched: boolean | undefined;
  value: string | number | undefined | null;
  enable: boolean;
  nonEditable?: boolean;
  isRequired?: boolean;
  width?: number | string;
  props?: TextFieldProps;
  sx?: {};
  color?: string;
  rows?:number;
  maxLength?: number;
  withoutLabel?: boolean;
  MultiLine?: boolean;
}

const CustomTextField = ({
  onChange,
  onBlur,
  onFocus,
  name,
  label,
  error,
  touched,
  value,
  enable,
  nonEditable,
  isRequired = false,
  props,
  sx,
  color,
  rows,
  width,
  maxLength,
  withoutLabel,
  MultiLine,
}: CustomTextFieldProps) => {
  return (
    <Box sx={{ width: width }}>
      <FormControl
        required={isRequired}
        sx={{ width: { width }, maxWidth: "100%" }}
      >
        {withoutLabel ? null : (
          <Typography sx={{ paddingLeft: "0.3rem", color: "white" }}>
            {label[0].toUpperCase() + label.slice(1)}
          </Typography>
        )}

        <TextField
          multiline={MultiLine}
          rows={rows?rows:7} // Set the number of rows you want
          size="small"
          autoComplete="off"
          type={props?.type ? props.type : "text"}
          onFocus={onFocus}
          disabled={nonEditable || !enable}
          required={isRequired}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value}
          variant="outlined"
          placeholder={
            withoutLabel ? label[0].toUpperCase() + label.slice(1) : ""
          }
          sx={{
            boxShadow: "none",
            backgroundColor: nonEditable || !enable ? "#ddd" : "#fff",
            borderRadius: "5px",
            ".MuiOutlinedInput-notchedOutline": {
              border: !!(error && touched) ? "1px solid #FF5630" : 0,
            },
            "& .MuiTextField-root": {
              maxWidth: "100%",
            },
            margin: "0.3rem 0rem",
            maxWidth: "100%",
            ...sx,
          }}
          inputProps={{
            maxLength: maxLength,
            style: {
              color: color ? color : "black",
            },
          }}
          {...props}
        />

        <FormHelperText
          sx={{
            color: "#FF5630",
          }}
        >
          {error && touched ? error : ""}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomTextField;

import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/material";
import PrimaryBtn from "../PrimaryBtn";
import CustomTextField from "../CustomTextField";
const AddText = ({
  projectID,
  getData,
}: {
  projectID: string;
  getData: any;
}) => {
  const initialValues = {
    text: "",
  };
  const AddTextSchema = Yup.object({
    text: Yup.string().required("Text is Required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddTextSchema}
      onSubmit={(values: any, { resetForm }: { resetForm: any }) => {
        getData({ type: "TEXT", urls: [values.text] });
        resetForm();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }: {
        values: any;
        touched: any;
        errors: any;
        handleChange: any;
        handleBlur: any;
        handleSubmit: any;
      }) => (
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <CustomTextField
            // withoutLabel
            enable
            isRequired
            name="text"
            label="text"
            value={values.text}
            error={errors.text}
            touched={touched.text}
            onChange={handleChange}
            onBlur={handleBlur}
            width="100%"
            props={{
              type: "text",
            }}
          />
          <Box sx={{ marginTop: "0rem" }}>
            <PrimaryBtn title="Save" />
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default AddText;

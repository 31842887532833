import { Box } from "@mui/material";
import React from "react";
import StaticBG from "../../../components/BG/StaticBG";
import NavBar from "../../../components/NavBar/NavBar";
import PageHeader from "../../../components/PageHeader";
import AddMemberForm from "./components/AddMemberForm";

const AddTeamMember = () => {
  return (
    <>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Box sx={{ width: "95%", margin: "2rem auto 1rem" }}>
        <PageHeader Header="Add Team Member" />
        <Box sx={{ width: "80%", margin: "0 auto" }}>
          <AddMemberForm />
        </Box>
      </Box>
    </>
  );
};

export default AddTeamMember;

import { Box, Grid, Typography } from '@mui/material'
import React, { ReactNode, useState } from 'react'

interface cardProps {
    data: { children: ReactNode, img: string }[]
}

const ScrollableImageCard = ({ data }: cardProps) => {

    const [activeCircle, setActiveCircle] = useState(0)

    return (
        <Box sx={{
            width: '90vw',
            position: 'relative',
            left: 'calc(-45vw + 50%)',
            height: {
                lg: "75vh",
                md: "75vh",
                sm: "100vh",
                xs: "100vh",
            },
            backgroundColor: '#111'
        }}>
            <Grid container sx={{
                height: '100%'
            }}>
                <Grid item lg={6} md={6} sm={6} xs={12} sx={{
                    padding: {
                        lg: '2rem 4rem',
                        md: '2rem 4rem',
                        sm: '3rem 2rem 1rem',
                        xs: '3rem 2rem 1rem',
                    },
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    position: 'relative',

                }}>
                    <Box sx={{ display: 'flex' }}>

                        {data.map((data, i) => (

                            <Box sx={{
                                color: 'white',
                                transform: activeCircle !== i ? 'translateX(-200%)' : 'translateX(0%)',
                                opacity: activeCircle === i ? 1 : 0,
                                transition: '0.8s',
                                position: 'absolute',
                                width: {
                                    lg: '70%',
                                    md: '70%',
                                    sm: '90%',
                                    xs: '90%',
                                },

                            }}>
                                {data.children}
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: '1rem',

                    }}>
                        {data.map((_, i) => (
                            <Box key={i} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: {
                                    lg: '0.5rem',
                                    md: '0.5rem',
                                    sm: "1rem",
                                    xs: "1rem",
                                },
                                boxSizing: 'border-box',
                                border: '2px solid  #DC2D0280',
                                width: '3rem',
                                height: '3rem',
                                borderRadius: '50%',
                                color: 'white',
                                cursor: 'pointer',
                                userSelect: 'none',
                                backgroundColor: activeCircle === i ? '#DC2D0240' : 'transparent',
                                transition: '0.3s'
                            }}
                                onClick={() => setActiveCircle(i)}
                            >
                                <Typography>
                                    {i + 1}
                                </Typography>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            <Typography sx={{ fontSize: '1rem', color: 'white', cursor: 'default' }}>and More </Typography>

                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} sx={{ overflow: 'hidden', maxHeight: '100%', position: 'relative' }}>
                    {data.map((data, i) => (<Box component='img' src={data.img} alt='' sx={{
                        // transform: activeCircle !== i ? 'translateX(200%)' : 'translateX(0%)',
                        opacity: activeCircle === i ? 1 : 0,
                        transition: '0.8s',
                        position: 'absolute',
                        filter: 'grayScale(1)',
                    }} />))}

                </Grid>
            </Grid>
        </Box>
    )
}

export default ScrollableImageCard
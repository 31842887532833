import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import DataCard from "./DataCard";
import Map from "../map/Map";
import { Box, Link } from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const OurData = ({
  mapLink,
  embeddedMapLink,
  address,
  phone,
  email,
}: {
  mapLink: string;
  embeddedMapLink: string;
  address: string;
  phone: string;
  email: string;
}) => {
  const icnStyle = { fontSize: "2.2rem", color: "white" };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        height: "100%",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      > */}
      {/* <Map Link={embeddedMapLink}/> */}
      <Link
        href={mapLink}
        target="_blank"
        rel="noopener"
        sx={{ textDecoration: "none", color: "white" }}
      >
        <DataCard
          title="Our Location"
          desc={
            <>{address}</>
          }
        >
          <PlaceOutlinedIcon sx={icnStyle} />
        </DataCard>
      </Link>
      {/* </Box> */}

      <Link
        href={`tel:${phone}`}
        target="_blank"
        rel="noopener"
        sx={{ textDecoration: "none", color: "white" }}
      >
        <DataCard title="Phone Number" desc={phone}>
          <LocalPhoneOutlinedIcon sx={icnStyle} />
        </DataCard>
      </Link>
      <Link
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener"
        sx={{ textDecoration: "none", color: "white" }}
      >
        <DataCard title="Email" desc={email} mb="0">
          <MailOutlinedIcon sx={icnStyle} />
        </DataCard>
      </Link>
    </Box>
  );
};

export default OurData;

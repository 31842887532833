import React, { useState } from "react";
import ImgUpload from "../img-upload/ImgUpload";
import { Box } from "@mui/material";
import PrimaryBtn from "../PrimaryBtn";

const AddImages = ({
  projectID,
  getData,
}: {
  projectID: string;
  getData: any;
}) => {
  const [UUIDs, setUUIDs] = useState<string[]>([]);
  const getUUIDs = (pramUUIDs: string[]) => {
    setUUIDs(pramUUIDs);
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}
    >
      <ImgUpload getUUIDs={getUUIDs} multiple/>
      <Box sx={{ marginTop: "0rem" }}>
        <PrimaryBtn
          title="Add"
          type="button"
          onClick={()=>getData({ type: "IMAGE", urls: UUIDs })}
        />
      </Box>
    </Box>
  );
};

export default AddImages;

import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

const UploadingImg = ({ src, progress }: { src: string; progress: number }) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (propValue: number) => {
    if (propValue !== undefined) {
      setValue(propValue);
    }
  };
  useEffect(() => {
    handleChange(progress);
  }, [progress]);

  return (
    <Grid
      item
      lg={4}
      md={4}
      sm={6}
      xs={12}
      sx={{ position: "relative", boxSizing: "border-box" }}
    >
      {value < 100 ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50% + 24px, -50% + 24px)",
            width: "100%",
            height: "100%",
            color: "white",
            zIndex: "1",
          }}
        >
          {value}
        </Box>
      ) : null}
      <Box
        component="img"
        src={src}
        alt="Selected"
        sx={{
          maxHeight: "100%",
          maxWidth: "100%",
          opacity: value < 100 ? "0.3" : "1",
        }}
        loading="lazy"
      />
    </Grid>
  );
};

export default UploadingImg;

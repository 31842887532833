import { Box } from "@mui/material";
import React from "react";
import TextSecton from "./TextSecton";
import ImagesSection from "./ImagesSection";

import AddProjectSection from "../../../../../components/sections/AddProjectSection";
import NeedsAuth from "../../../../../components/NeedsAuth";
import VideoSection from "./VideoSection";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
const Sections = ({
  obj,
  id,
  onClick,
  getData,
  deleteSections
}: {
  obj: { type: string; urls: string[] };
  id: string;
  onClick: () => void;
  getData: any;
  deleteSections: any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <NeedsAuth Allowed={["admin"]}>
        <Box
          sx={{
            padding: "0.5rem",
            aspectRatio: "1/1",
            borderRadius: "5px",
            backgroundColor: "Red",
            position: "absolute",
            top: "0",
            right: "0",
            cursor: "pointer",
            zIndex:"4"
          }}
          onClick={deleteSections}
        >
          <DeleteRoundedIcon sx={{ color: "white" }} />
        </Box>
      </NeedsAuth>
      <Box sx={{ margin: "1rem 0", width: "100%" }}>
        {obj.type === "TEXT" ? (
          <TextSecton text={obj.urls[0]} />
        ) : obj.type === "IMAGE" ? (
          <ImagesSection imgs={obj.urls} />
        ) : (
          <VideoSection videos={obj.urls} />
        )}
      </Box>
      <NeedsAuth Allowed={["admin"]}>
        <AddProjectSection
          projectID={id ? id : ""}
          onClick={onClick}
          getData={getData}
        />
      </NeedsAuth>{" "}
    </Box>
  );
};

export default Sections;

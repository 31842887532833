import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading";
import { API_URL } from "../../data/API";

const pages = ["Home", "Our Team"];

function NavBar() {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/category/home`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result.categories);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const { auth, setAuth } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handlePath = (btnTitle: string) => {
    let Path: string = "";

    if (btnTitle === "home") {
      Path = "/home";
    } else if (btnTitle === "our team") {
      Path = "/team";
    }
    // else if (btnTitle === "keep in touch") {
    //   Path = "/contactus";
    // }
    return Path;
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAuth({});
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <AppBar
      sx={{
        position: "absolute",
        marginBottom: "1rem",
        backgroundColor: "transparent",
        color: "white",
        padding: "10px 10px",
        boxShadow: "none",
        width: "100%",
        margin: "0 auto",
        zIndex: "3",
      }}
    >
      <Container maxWidth="xl" id="nav">
        <Toolbar
          disableGutters
          sx={{
            justifyContent: "space-between",
            width: "80%",
            marginX: "auto",
          }}
        >
          <Link
            to="/home"
          >
            <Box
              component="img"
              src="https://i.postimg.cc/brmRcPLT/logo.png"
              alt=""
              sx={{
                width: "5rem",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            ></Box>
          </Link>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              width: "60%",
            }}
          >
            {pages.map((page) => (
              <Link
                to={handlePath(page.toLowerCase())}
                style={{ textDecoration: "none" }}
                key={page}
              >
                <Box sx={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: 'white',
                  transform: "scaleX(0)",
                  transition: "0.3s",
                  '&:hover': {
                    transform: "scaleX(1)",
                  }
                }} />
                <Button
                  id={page}
                  onClick={(e) => handleCloseNavMenu()}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",

                  }}
                >

                  {page}
                </Button>
              </Link>
            ))}
            <Button
              type="button"
              sx={{
                my: 2,
                color: "white",
                display: "block",
                "&:hover": {
                  // color: theme.sec_color,
                },
              }}
              onClick={handleMenu}
            >
              Departments
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ zIndex: '1400' }}
            >
              {data.map((Category: any, idx: number) => (
                <Link
                  to={`/categories/${Category.id}`}
                  key={idx}
                  style={{ textDecoration: "none", marginTop: "-1rem", color: "black" }}
                >

                  <MenuItem onClick={handleClose} sx={{ padding: "0.5rem 2rem", boxSizing: "border-box", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>                  <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
                    height: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "1rem",
                      xs: "1rem",
                    }, marginRight: '1rem',
                    filter: ' invert(100%)'

                  }}></Box>{Category.name}</MenuItem>
                </Link>
              ))}
            </Menu>

            {/* <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button
                type="submit"
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  "&:hover": {
                    // color: theme.sec_color,
                  },
                }}
                onClick={() => (auth.accessToken ? handleLogout() : null)}
              >
                {auth.accessToken ? "logout" : "Login"}
              </Button>
            </Link> */}
            <Link
              to='/contactus'
              style={{ textDecoration: "none" }}
            >
              <Box sx={{
                width: "100%",
                height: "1px",
                backgroundColor: 'white',
                transform: "scaleX(0)",
                transition: "0.3s",
                '&:hover': {
                  transform: "scaleX(1)",
                }
              }} />
              <Button

                onClick={(e) => handleCloseNavMenu()}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  background: "linear-gradient(45deg, rgba(1,0,16,0) 20%, rgb(146 10 10) )",

                }}
              >
                KEEP IN TOUCH
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "space-between",
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
            }}
          >
            <Link to="/">
              <Box
                component="img"
                src="https://i.postimg.cc/brmRcPLT/logo.png"
                alt=""
                sx={{
                  width: "7rem",
                  display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                  mr: 1,
                }}
              ></Box>
            </Link>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "white", fontSize: "2rem" }} />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link
                  to={handlePath(page.toLowerCase())}
                  style={{ textDecoration: "none" }}
                  key={page}
                >
                  <MenuItem onClick={handleCloseNavMenu}>

                    <Typography textAlign="center" sx={{ color: "black" }}>
                      {page}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
              <MenuItem
                sx={{
                  color: "black",
                  display: "flex",
                  alignItems: 'center',

                }}
                onClick={handleMenu}
              >
                Departments
              </MenuItem>
              {/* <Link to={"/login"} style={{ textDecoration: "none" }}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    sx={{
                      color: "black",
                      display: "block",
                      "&:hover": {
                        // color: theme.sec_color,
                      },
                    }}
                    onClick={() => (auth.accessToken ? handleLogout() : null)}
                  >
                    {auth.accessToken ? "logout" : "Login"}
                  </Typography>
                </MenuItem>
              </Link> */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
}

export default NavBar;

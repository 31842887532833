import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import UploadingImg from "./UploadingImg";

const S3_BUCKET = "hplus-media-bucket";
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const ImgUpload = ({
  getUUIDs,
  multiple
}: {
  getUUIDs: any;
  multiple?:boolean
}) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [progress, setProgress] = useState<number[]>([]);
  const [UUID, setUUID] = useState<string[]>([]);

  const btn = useRef<HTMLInputElement>(null);

  const uploadFile = (file: any, index: number) => {
    const uuidString = uuidv4();
    const fileExtension = file?.name.split(".").pop() || "";
    const newFilename = `${uuidString}.${fileExtension}`;
    setUUID((prevUUIDs) => [...prevUUIDs, newFilename]);

    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: newFilename,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const updatedProgress = [...progress];
        updatedProgress[index] = Math.round((evt.loaded / evt.total) * 100);
        setProgress(updatedProgress);
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };

  const handleFileInput = (e: any) => {
    setSelectedFile([...e.target.files]);
    [...e.target.files].map((file: any, idx: number) => uploadFile(file, idx));
  };

  const [firstRender, setFirstRender] = useState(true);
  const [secondRender, setSecondRender] = useState(true);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else if (secondRender) {
      setSecondRender(false);
    } else {
      getUUIDs(UUID);
    }
  }, [UUID, firstRender]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1f1f1f",
          boxSizing: "border-box",
          // borderRadius: "10px",
          width: "100%",
          height: "100%",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        onClick={() => btn.current?.click()}
      >
        {selectedFile !== null ? (
          <Grid container spacing={3}>
            {selectedFile.map((file: any, idx: number) => (
              <UploadingImg
                src={URL.createObjectURL(file)}
                progress={progress[idx]}
                key={idx}
              />
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              borderRadius: "10px",
              border: "#aaa 2px solid",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4rem 0",
            }}
          >
            <AddPhotoAlternateIcon sx={{ color: "#aaa", fontSize: "3rem" }} />
          </Box>
        )}
      </Box>
      <Button
        variant="contained"
        component="label"
        sx={{
          width: "100%",
          borderRadius: "0",
          backgroundColor: "red",
          display: "none",
        }}
      >
        Upload Photo
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleFileInput}
          ref={btn}
          multiple = {multiple}
        />
      </Button>
    </>
  );
};

export default ImgUpload;

import React, { Children, ReactNode } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const CustomBox = ({ children, title }: { children: any; title: string }) => {
  return (
    <Box
      sx={{
        width: {
          lg:"25rem",
          md:"25rem",
          sm:"100%",
          xs:"100%",
        },
        
        // height: "30rem",
        overflowY: "auto",
        padding: "2rem",
        backgroundColor: "#11111100",
        boxSizing:"border-box",
        borderRadius: "8px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography sx={{ color: "white", fontSize: "1.5rem" }}>
          {title}
        </Typography>
        <Box
          sx={{
            backgroundColor: "red",
            width: "10%",
            height: "2px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
      <Box sx={{marginY:"1.5rem"}}>{children}</Box>
    </Box>
  );
};

export default CustomBox;

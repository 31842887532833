import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";

const Missing = () => {
  return (
    <><NavBar /><Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <Typography sx={{ fontSize: "7rem", fontWeight: "600" }}>404</Typography>
      <Typography sx={{ color: "red", marginTop: "-1.5rem" }}>
        Page Not Found
      </Typography>
      <Link to="/home">
        <button
          type="button"
          style={{
            marginTop: "1rem",
            backgroundColor: "red",
            color: "white",
            padding: "1rem 2rem",
            textDecoration: "none",
            border: "0",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Back To Home
        </button>
      </Link>
    </Box></>
  );
};

export default Missing;

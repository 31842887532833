import { Box } from '@mui/material'
import React from 'react'
import Corner from './Corner'
import LiveIcon from './LiveIcon'

const LiveBackground = () => {
  
  return (
    <Box sx={{
      width: '97vw',
      marginX: 'auto',
      position: 'absolute',
      left: 'calc(-48.5vw + 50%)',

    }} data-aos="fade-up">
      <LiveIcon />
      <Box sx={{
        position: 'relative', width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: '-1',
      }}>
        <Box sx={{
          width: '100%',
          height: '10vh',
          background: `radial-gradient(circle, #fe034e88 , #00000000 80%)`,
          filter: 'blur(10px)',
          position: 'absolute',
          top: '0',
          zIndex: '1',
        }} />
        <Box sx={{
          width: '99vw',
          left: 'calc(-49.5vw + 50%)',
          height: '140vh',
          backgroundColor: 'black',
          filter: 'blur(30px)',
          position: 'absolute',
          top: '0',
          zIndex: '0',
          overflow: 'hidden',
        }} >
          <Box sx={{
            width: '50rem',
            height: '50rem',
            background: `radial-gradient(circle, #fe034e44 , #00000000 80%)`,
            filter: 'blur(20px)',
            position: 'absolute',
            top: '0',
            left:'-10rem',
            zIndex: '1',
          }} />
        </Box>
        <Corner pos='TopLeft' />
        <Corner pos='TopRight' />
      </Box>
    </Box>
  )
}

export default LiveBackground
// import emailjs from "@emailjs/browser";
import PrimaryBtn from "../../../../../components/PrimaryBtn";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Grid } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";

const Form = () => {
  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   emailjs
  //     .sendForm(
  //       "service_ftx6mnp",
  //       "template_180x6s9",
  //       form.current,
  //       "7hSMjJqxJwjW8GIdb"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   e.target.reset();
  // };

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const AddPatientSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    email: Yup.string().required("Email is Required"),
    subject: Yup.string().required("Subject is Required"),
    message: Yup.string().required("Message is Required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddPatientSchema}
      onSubmit={(values: any, { resetForm }: { resetForm: any }) => {
        console.log(values);
        resetForm();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }: {
        values: any;
        touched: any;
        errors: any;
        handleChange: any;
        handleBlur: any;
        handleSubmit: any;
      }) => (
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTextField
                withoutLabel
                enable
                isRequired
                name="name"
                label="Name"
                value={values.name}
                error={errors.name}
                touched={touched.name}
                onChange={handleChange}
                onBlur={handleBlur}
                width="100%"
                props={{
                  type: "text",
                }}
                color="white"
                sx={{ backgroundColor: "#ffffff20" }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTextField
                withoutLabel
                enable
                isRequired
                name="email"
                label="Email"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
                width="100%"
                props={{
                  type: "text",
                }}
                color="white"
                sx={{ backgroundColor: "#ffffff20" }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTextField
                withoutLabel
                enable
                isRequired
                name="subject"
                label="Subject"
                value={values.subject}
                error={errors.subject}
                touched={touched.subject}
                onChange={handleChange}
                onBlur={handleBlur}
                width="100%"
                props={{
                  type: "text",
                }}
                color="white"
                sx={{ backgroundColor: "#ffffff20" }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomTextField
                withoutLabel
                enable
                isRequired
                MultiLine
                rows={4}
                name="message"
                label="Message"
                value={values.message}
                error={errors.message}
                touched={touched.message}
                onChange={handleChange}
                onBlur={handleBlur}
                width="100%"
                props={{
                  type: "text",
                }}
                color="white"
                sx={{ backgroundColor: "#ffffff20" }}
              />
            </Grid>
          </Grid>

          <Box sx={{ marginTop: "0rem" }}>
            <PrimaryBtn title="Submit" />
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default Form;

import { Box, Typography } from '@mui/material'
import React from 'react'

interface ScrollableCardChildProps {
    title: string
    bio: string
}

const ScrollableCardChild = ({ title, bio }: ScrollableCardChildProps) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
                    height: {
                        lg: "2.5rem",
                        md: "1.5rem",
                        sm: "1.5rem",
                        xs: "1.5rem",
                    }, marginRight: '1rem',
                }} />
                <Typography sx={{ fontSize:  {
                        lg: "2.8rem",
                        md: "2.8rem",
                        sm: "2rem",
                        xs: "2rem",
                      }, fontWeight: 'bold' }}>
                    {title}
                </Typography>
            </Box>
            <Typography sx={{ fontSize:   {
                        lg: "1.5rem",
                        md: "1.5rem",
                        sm: "1.2rem",
                        xs: "1.2rem",
                      },}}>
                {bio}
            </Typography>
        </Box >
    )
}

export default ScrollableCardChild
import { Box, Typography } from "@mui/material";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";

const VerticalProjectCard = ({
  projectImageSrc,
  projectName,
}: {
  projectImageSrc: string;
  projectName: string;
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useEffect(() => {
    if (!projectImageSrc.includes("https")) {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      const s3 = new AWS.S3();
      const bucketName = process.env.REACT_APP_S3_BUCKET;
      if (projectImageSrc !== null) {
        s3.getSignedUrl(
          "getObject",
          { Bucket: bucketName, Key: projectImageSrc, Expires: 60 },
          (err, url) => {
            if (err) {
              console.error("Error generating signed URL:", err);
              return;
            }
            setImageUrl(url);
          }
        );
      }
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        backgroundColor: "#00000080",
        backdropFilter: "blur(10px)",
        position: "relative",
        transform: "scale(1)",
        transition: "0.3s ease",
        cursor: "pointer",
        "&:hover": {
          // transform: "scale(1.05)",
        },
      }}
    >
      <Box
        component="img"
        src={
          imageUrl ? imageUrl : "https://i.postimg.cc/QtB5JtTk/Untitled-1.jpg"
        }
        sx={{ maxWidth: "100%", maxHeight: "100%" }}
        loading="lazy"
      />

      <Box sx={{
        width: "100%",
        height: 'calc(100% - 3.5rem)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: "linear-gradient(180deg, rgba(1,0,16,0) 0%, rgba(1,0,16,1) 100%)",
        position: 'absolute',
        bottom: '3.5rem',
        filter: "opacity(0)",
        transition: '0.3s ease',
        '&:hover': {
          filter: "opacity(0.7)",

        }
      }}>

        <Box
          component="img"
          src="https://i.postimg.cc/gjXTHBJ9/logo-stroke.png"
          sx={{ maxWidth: "100%", maxHeight: "100%" }}
          loading="lazy"
        />
      </Box>

      <Box>
        <Typography
          sx={{ color: "white", padding: "1rem", textAlign: "center" }}
        >
          {projectName}
        </Typography>
      </Box>
    </Box >
  );
};

export default VerticalProjectCard;

import { Box, Typography } from '@mui/material'
import React from 'react'

const getRandomPastelColor = () => {
    const getRandomComponent = () => Math.floor(Math.random() * 255 + 0); // Generates random component between 128 and 255 (pastel range)
    const r = getRandomComponent();
    const g = getRandomComponent();
    const b = getRandomComponent(); // Fixed blue component for pastel blue
    return `rgb(${r},${g},${b})`;
};
const ServiceCard = ({ Service }: { Service: { name: string, icon: any, sx?: any } }) => {
    const pastelColor = getRandomPastelColor();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: "2rem 2rem",
            boxSizing: 'border-box',
            backgroundColor: pastelColor,
            color: 'white',
            borderRadius: "10px",
            fontSize: '3rem',
            ...Service.sx
        }}>
            {Service.icon}
            <Typography sx={{ fontSize: '1.5rem', fontWeight: "600", color: 'inherit' }}>{Service.name.toUpperCase()}</Typography>
        </Box>
    )
}

export default ServiceCard
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import PrimaryBtn from "../../../../components/PrimaryBtn";
import CustomTextField from "../../../../components/CustomTextField";
import { useNavigate, useParams } from "react-router-dom";
import CustomSelectField from "../../../../components/CustomSelectField";
import { API_URL } from "../../../../data/API";
import Loading from "../../../../components/Loading";
import useAuth from "../../../../hooks/useAuth";
import ImgUpload from "../../../../components/img-upload/ImgUpload";

const CreateNewProject = ({
  id,
  edit,
  init,
  updateId,
}: {
  id?: string;
  edit?: boolean;
  init?: {
    name: string;
    description: string;
    memberIds: {}[];
    atHome: boolean;
  };
  updateId?: string;
}) => {
  const [UUIDs, setUUIDs] = useState<string[]>([]);
  const getUUIDs = (pramUUIDs: string[]) => {
    setUUIDs(pramUUIDs);
  };
  const initialValues = {
    name: "",
    description: "",
    memberIds: [],
    atHome: false,
  };
  const AddProjectSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    memberIds: Yup.array().of(Yup.string()).required("Member is Required"),
  });
  const navigate = useNavigate();
  const [flag, setFlag] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [membersData, setMembersData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [membersError, setMembersError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async (url: string, state: any, error: any) => {
      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        state(result);
      } catch (error: any) {
        error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(`${API_URL}/category`, setData, setError);
    fetchData(`${API_URL}/member`, setMembersData, setMembersError);
  }, []);
  const { auth } = useAuth();
  const createProject = async (values: {
    name: string;
    description: string;
    memberIds: string[];
    atHome: boolean;
  }) => {
    try {
      const url = `${API_URL}/project`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: id
          ? UUIDs
            ? JSON.stringify({
                ...values,
                categoryId: parseInt(id),
                pictureUrl: UUIDs[0],
              })
            : JSON.stringify({ ...values, categoryId: parseInt(id) })
          : UUIDs
          ? JSON.stringify({ ...values, pictureUrl: UUIDs[0] })
          : JSON.stringify(values),
      });

      if (response.ok) {
        const data = await response.json();
        // navigate(`/projects/${data.project.id}`);
        window.location.reload();
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const [editData, setEditData] = useState<any>(null);
  const [editError, seEdittError] = useState<string | null>(null);
  const editProject = async (values: {
    name: string;
    description: string;
    memberIds: string[];
    atHome: boolean;
  }) => {
    try {
      const response = await fetch(`${API_URL}/project/${updateId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: UUIDs
          ? JSON.stringify({ ...values, pictureUrl: UUIDs[0] })
          : JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setEditData(result);
      window.location.reload();
    } catch (error: any) {
      seEdittError(
        error.message || "An error occurred while fetching the data."
      );
    }
  };

  const getCategoriesNames = (arr: {}[], wanted: string) => {
    const categoriesNames: any[] = [];
    if (arr?.length > 0) {
      arr.map((ele: any) => {
        const value = wanted === "id" ? parseInt(ele[wanted]) : ele[wanted];
        categoriesNames.push(value);
      });
    }
    return categoriesNames;
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Typography
        sx={{ color: "white", marginBottom: "0.5rem", fontSize: "1.5rem" }}
      >
        {edit ? "Edit Project" : "Add New Project"}
      </Typography>
      <Box
        sx={{
          width: "5rem",
          height: "2px",
          marginBottom: "1rem",
          backgroundColor: "red",
        }}
      ></Box>
      <Box sx={{ width: "100%", marginBottom: "1rem" }}>
        <ImgUpload getUUIDs={getUUIDs} />
      </Box>
      <Formik
        initialValues={init ? init : initialValues}
        validationSchema={AddProjectSchema}
        onSubmit={(values: any, { resetForm }: { resetForm: any }) => {
          edit ? editProject(values) : createProject(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }: {
          values: any;
          touched: any;
          errors: any;
          handleChange: any;
          handleBlur: any;
          handleSubmit: any;
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomTextField
                  // withoutLabel
                  enable
                  isRequired
                  name="name"
                  label="Name"
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="100%"
                  props={{
                    type: "text",
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomTextField
                  // withoutLabel
                  enable
                  isRequired
                  name="description"
                  label="description"
                  value={values.description}
                  error={errors.description}
                  touched={touched.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="100%"
                  props={{
                    type: "text",
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomSelectField
                  options={getCategoriesNames(membersData?.members, "name")}
                  idxs={getCategoriesNames(membersData?.members, "id")}
                  enable
                  isRequired
                  name="memberIds"
                  label="Members"
                  value={values.memberIds}
                  error={errors.memberIds}
                  touched={touched.memberIds}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.atHome}
                        sx={{ color: "white" }}
                        color="error"
                      />
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.atHome}
                    name="atHome"
                    label="Show in Home"
                    sx={{ color: "White" }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: "0rem" }}>
              <PrimaryBtn
                title={edit ? "Save" : "Add"}
                onClick={() => setFlag(!flag)}
              />
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default CreateNewProject;

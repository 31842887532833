import React, { useState } from "react";
import ImgUpload from "../../../../../components/img-upload/ImgUpload";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Grid, Typography } from "@mui/material";
import CustomTextField from "../../../../../components/CustomTextField";
import PrimaryBtn from "../../../../../components/PrimaryBtn";
import { API_URL } from "../../../../../data/API";
import useAuth from "../../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
const CategoryForm = ({
  edit,
  init,
  id,
}: {
  edit?: boolean;
  init?: { name: string; description: string; pictureUrl: string };
  id?: string;
}) => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const [UUIDs, setUUIDs] = useState<string[]>([]);
  const getUUIDs = (pramUUIDs: string[]) => {
    setUUIDs(pramUUIDs);
  };

  const initialValues = {
    name: "",
    description: "",
    pictureUrl: "",
  };
  const AddProjectSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    description: Yup.string(),
    pictureUrl: Yup.string(),
  });

  const createProject = async (values: {
    name: string;
    description: string;
  }) => {
    try {
      const response = await fetch(`${API_URL}/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify(
          UUIDs.length > 0 ? { ...values, pictureUrl: UUIDs[0] } : values
        ),
      });

      if (response.ok) {
        const data = await response.json();
        navigate(`/categories/${data.category.id}`);
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const [editData, setEditData] = useState<any>(null);
  const [editError, seEdittError] = useState<string | null>(null);
  const editCategory = async (values: {
    name: string;
    description: string;
    pictureUrl: string;
  }) => {
    try {
      const response = await fetch(`${API_URL}/category/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify(
          UUIDs.length > 0
            ? { ...values, pictureUrl: UUIDs[0] }
            : values
        ),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setEditData(result);
      window.location.reload();
    } catch (error: any) {
      seEdittError(
        error.message || "An error occurred while fetching the data."
      );
    }
  };

  return (
    <>
      <Typography
        sx={{ color: "white", marginBottom: "1rem", fontSize: "1.5rem" }}
      >
        {edit ? "Edit Category" : "Add New Category"}
      </Typography>
      <Box sx={{ width: "100%", marginBottom: "1rem" }}>
        <ImgUpload getUUIDs={getUUIDs} />
      </Box>
      <Formik
        initialValues={init ? init : initialValues}
        validationSchema={AddProjectSchema}
        onSubmit={(values: any, { resetForm }: { resetForm: any }) => {
          edit ? editCategory(values) : createProject(values);
          resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }: {
          values: any;
          touched: any;
          errors: any;
          handleChange: any;
          handleBlur: any;
          handleSubmit: any;
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Grid container>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomTextField
                  // withoutLabel
                  enable
                  isRequired
                  name="pictureUrl"
                  label="Or Video Link"
                  value={values.pictureUrl}
                  error={errors.pictureUrl}
                  touched={touched.pictureUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="100%"
                  props={{
                    type: "text",
                  }}
                />
              </Grid> */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomTextField
                  // withoutLabel
                  enable
                  isRequired
                  name="name"
                  label="Name"
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="100%"
                  props={{
                    type: "text",
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomTextField
                  // withoutLabel
                  // MultiLine
                  // rows={2}
                  enable
                  isRequired
                  name="description"
                  label="Description"
                  value={values.description}
                  error={errors.description}
                  touched={touched.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="100%"
                  props={{
                    type: "text",
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ marginTop: "0rem" }}>
              <PrimaryBtn title={edit ? "Save" : "Add"} />
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default CategoryForm;

import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from "@mui/material";
import React from "react";
export interface CustomSelectFieldProps {
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: any) => void;
  name: string;
  label: string;
  error: string | undefined;
  touched: boolean | undefined;
  value: string;
  enable: boolean;
  nonEditable?: boolean;
  isRequired?: boolean;
  width?: number | string;
  sx?: {};
  options: string[];
  idxs?: string[];
}
const CustomSelectField = ({
  onChange,
  onBlur,
  onFocus,
  name,
  label,
  error,
  touched,
  value,
  enable,
  nonEditable,
  sx,
  options,
  idxs,
}: CustomSelectFieldProps) => {
  return (
    <FormControl fullWidth>
      <Typography sx={{ paddingLeft: "0.3rem", color: "white" }}>
        {label[0].toUpperCase() + label.slice(1)}
      </Typography>
      <Select
        multiple
        value={value}
        label=""
        onChange={(e: any) => onChange(e)}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        size="small"
        sx={{
          boxShadow: "none",
          backgroundColor: nonEditable || !enable ? "#ddd" : "#eee",
          borderRadius: "5px",
          ".MuiOutlinedInput-notchedOutline": {
            border: !!(error && touched) ? "1px solid #FF5630" : 0,
          },
          "& .MuiTextField-root": {
            color: "white",

            maxWidth: "100%",
          },
          color: "black !important  ",

          margin: "0.3rem 0rem",
          maxWidth: "100%",

          ...sx,
        }}
      >
        {options.map((options: string, idx: number) => (
          <MenuItem value={idxs ? idxs[idx] : options} key={idx}>
            {options}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText
        sx={{
          color: "#FF5630",
        }}
      >
        {error && touched ? error : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomSelectField;

import { Box, Typography } from '@mui/material';
import React from 'react';

const CircularRepeatText: React.FC<{ sentence: string }> = ({ sentence }) => {
    return (
        <Box sx={{ position: 'relative', width: '100%', overflow: 'hidden', height: '12rem', overflowX: 'hidden' }}>
            <Typography
                sx={{
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    animation: 'scrollTextStart 14s linear infinite',
                    /* Adjust animation duration as needed */
                    color: 'white',
                    fontSize: {
                        lg: "8rem",
                        md: "8rem",
                        sm: "4rem",
                        xs: "4rem",
                      },
                    fontWeight: 'bold',
                    overflow: 'hidden' // Added overflow: hidden to prevent text from re-entering
                }}
            >
                <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
                    height:  {
                        lg: "5rem",
                        md: "5rem",
                        sm: "3rem",
                        xs: "3rem",
                      },
                    marginX: '3rem'
                }} />{sentence}
            </Typography>
            <Typography
                sx={{
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    animation: 'scrollTextEnd 14s linear infinite',
                    /* Adjust animation duration as needed */
                    color: 'white',
                    fontSize: {
                        lg: "8rem",
                        md: "8rem",
                        sm: "4rem",
                        xs: "4rem",
                      },
                    fontWeight: 'bold',
                    overflow: 'hidden' // Added overflow: hidden to prevent text from re-entering
                }}
            >
                <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
                    height:  {
                        lg: "5rem",
                        md: "5rem",
                        sm: "3rem",
                        xs: "3rem",
                      },
                    marginX: '3rem'
                }} />{sentence}
            </Typography>
            <style>
                {`
          @keyframes scrollTextStart {
            from { transform: translateX(0%); }
            to { transform: translateX(-100%); }
          }
          @keyframes scrollTextEnd {
            from { transform: translateX(100%); }
            to { transform: translateX(0); }
          }
        `}
            </style>
        </Box>
    );
};

export default CircularRepeatText;

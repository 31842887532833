import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TeamMemberCardImage from "./TeamMemberCardImage";
import { Link } from "react-router-dom";
import NeedsAuth from "../../../../components/NeedsAuth";
import { API_URL } from "../../../../data/API";
import useAuth from "../../../../hooks/useAuth";
import AWS from "aws-sdk";
const TeamMemberCard = ({
  id,
  name,
  pos,
  team,
  UUID,
}: {
  id: string;
  name: string;
  pos: string;
  team: string;
  UUID: string;
}) => {
  const { auth } = useAuth();

  async function deleteMember(memberId: string) {
    try {
      const response = await fetch(`${API_URL}/member/${memberId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete member with ID ${memberId}`);
      }
      window.location.reload();
      console.log(`Member with ID ${memberId} deleted successfully`);
    } catch (error: any) {
      console.error("Error deleting member:", error.message);
    }
  }

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_S3_BUCKET;
    if (UUID !== null) {
      s3.getSignedUrl(
        "getObject",
        { Bucket: bucketName, Key: UUID, Expires: 60 },
        (err, url) => {
          if (err) {
            console.error("Error generating signed URL:", err);
            return;
          }
          setImageUrl(url);
        }
      );
    }
  }, []);


  return (
    <Box
      sx={{
        backgroundColor: "#1f1f1f90",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem",
        boxSizing: "border-box",
        borderRadius: "10px",
        backdropFilter: "blur(5px)",
      }}
    >
      <TeamMemberCardImage
        team={team}
        src={
          UUID !== null
            ? imageUrl ?? "https://i.postimg.cc/y6jCwkfY/user.jpg"
            : "https://i.postimg.cc/y6jCwkfY/user.jpg"
        }
      />
      <Box sx={{ marginTop: "0.5rem" }}>
        <Typography sx={{ color: "white", fontWeight: "600" }}>
          {name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ color: "#555" }}>
          {pos} | {team}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "50%",
          margin: "0 auto",
          marginTop: "1rem",
        }}
      >
        <Link
          to={`team-member/${id}`}
          style={{
            textDecoration: "none",
            width: "100% ",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ cursor: "pointer", color: "white", marginBottom: "0.5rem" }}
          >
            View More
          </Typography>
        </Link>
        <NeedsAuth Allowed={["admin"]}>
          <Typography
            sx={{
              cursor: "pointer",
              color: "red",
              opacity: "0.6",
              marginBottom: "0.5rem",
            }}
            onClick={() => deleteMember(id)}
          >
            Delete Member
          </Typography>
        </NeedsAuth>
        {/* <OpenInFullIcon /> */}
      </Box>
    </Box>
  );
};

export default TeamMemberCard;

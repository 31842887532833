import { Box, Grid, Typography } from "@mui/material";
import StaticBG from "../../../components/BG/StaticBG";
import NavBar from "../../../components/NavBar/NavBar";
import TeamMemberHeader from "./components/TeamMemberHeader";
import VerticalProjectCard from "../projects/components/Cards/VerticalProjectCard";
import HorizontalProjectCard from "../projects/components/Cards/HorizontalProjectCard";
import ImageOnlyCard from "../projects/components/Cards/ImageOnlyCard";
import ThumbnailProjectCard from "../projects/components/Cards/ThumbnailProjectCard";
import NeedsAuth from "../../../components/NeedsAuth";
import { Link, useParams } from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Loading from "../../../components/Loading";
import { useState, useEffect } from "react";
import { API_URL } from "../../../data/API";
const TeamMember = () => {
  const { id } = useParams();

  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/member/${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error: any) {
        setError(error.message || "An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Box sx={{ width: "95%", margin: "2rem auto 1rem" }}>
        <NeedsAuth Allowed={["admin"]}>
          <Link
            to={`/team/edit-member/${id}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              sx={{
                color: "white",
                border: "1px solid red",
                padding: "1rem 2rem",
                boxSizing: "border-box",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                width: "20rem",
                margin: "0 auto 1rem",
              }}
            >
              <Typography>Edit Team Member</Typography>
              <EditRoundedIcon />
            </Box>
          </Link>
        </NeedsAuth>
        <TeamMemberHeader
          imgSrc={data.member.pictureUrl}
          name={data.member.name}
          position={data.member.role}
          quote="Make it REAL!"
        />
        <Box sx={{ marginTop: "1rem", position: "relative" }}>
          <Grid
            container
            // spacing={2}
            sx={{ justifyContent: "center", alignItems: "center", width:"70%", margin:"0 auto" }}
          >
            {data.member.projects.map((project: any, idx: number) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={idx}>
                <Link
                  to={`/projects/${project.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <VerticalProjectCard
                    projectImageSrc={
                      project.pictureUrl !== null
                        ? project.pictureUrl
                        : "https://i.postimg.cc/QtB5JtTk/Untitled-1.jpg"
                    }
                    projectName={project.name}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>

          {/* <Grid item lg={3} md={4} sm={6} xs={12}>
              <VerticalProjectCard
                projectImageSrc="https://i.postimg.cc/7P7z4y1L/Mahmoud-Elblesy.jpg"
                projectName="National Team"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <VerticalProjectCard
                projectImageSrc="https://i.postimg.cc/mkbjf6FV/omar-mahmoud.jpg"
                projectName="Winners"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <VerticalProjectCard
                projectImageSrc="https://i.postimg.cc/RZJnZ5Kh/image.jpg"
                projectName="Thanks"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <VerticalProjectCard
                projectImageSrc="https://i.postimg.cc/rw91190L/5-Days-Left.jpg"
                projectName="Count Down"
              />
            </Grid>
          </Grid>
          <Box sx={{ marginTop: "1rem", height: "30rem" }}>
            <HorizontalProjectCard
              projectImageSrc="https://i.postimg.cc/9f9Yy1KP/5-Days-To-Go.png"
              projectName="EMPF World Championship Countdown"
            />
          </Box> */}
          {/* <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <ThumbnailProjectCard
                projectImageSrc="https://i.postimg.cc/7P7z4y1L/Mahmoud-Elblesy.jpg"
                projectName="National Team"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <ThumbnailProjectCard
                projectImageSrc="https://i.postimg.cc/mkbjf6FV/omar-mahmoud.jpg"
                projectName="Winners"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <ThumbnailProjectCard
                projectImageSrc="https://i.postimg.cc/RZJnZ5Kh/image.jpg"
                projectName="Thanks"
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <ThumbnailProjectCard
                projectImageSrc="https://i.postimg.cc/rw91190L/5-Days-Left.jpg"
                projectName="Count Down"
              />
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </>
  );
};

export default TeamMember;

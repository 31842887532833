import { Box, Typography } from '@mui/material'
import React from 'react'
import AnimatedTitle from '../../../../components/AnimatedTitle'

const Bio = () => {
    return (
        <Box sx={{ height: '100vh', overflow: 'hidden', position: 'relative' }}>
            <Box sx={{
                background: 'linear-gradient(to bottom right, #DC2D02, #160259)',
                position: 'absolute',
                // filter: "blur(20px)",
                width: {
                    lg: "75%",
                    md: "75%",
                    sm: "100%",
                    xs: "100%",
                },
                height: '100%',
                mixBlendMode: 'multiply',
            }} />
            <Box sx={{
                zIndex: '1', width: {
                    lg: "60%",
                    md: "60%",
                    sm: "85%",
                    xs: "85%",
                }, position: 'absolute', top: {
                    lg: "50%",
                    md: "50%",
                    sm: "75%",
                    xs: "75%",
                }, left: {
                    lg: "30rem",
                    md: "30rem",
                    sm: "2rem",
                    xs: "2rem",
                }, transform: "translate(0%,-50%)"
            }}>
                <Typography sx={{
                    color: 'white', fontSize: {
                        lg: "3rem",
                        md: "3rem",
                        sm: "2rem",
                        xs: "1.5rem",
                    },
                }} data-aos="fade-up">
                    <Box component='img' src="https://i.postimg.cc/jjgdm6c4/H-LOGO-white-copy.png" sx={{
                        display:{
                            lg: "inline-block",
                            md: "inline-block",
                            sm: "block",
                            xs: "block",
                          },
                        mb:{
                            lg: 0,
                            md: 0,
                            sm: 2,
                            xs: 2,
                          },
                        width: {
                            lg: "25rem",
                            md: "25rem",
                            sm: "15rem",
                            xs: "15rem",
                        }, marginBottom: '-1rem'
                    }} /> is one of the top multimedia companies that elevate your media to heights. We specialize in delivering  <span style={{ fontWeight: 'bold' }}> 360 </span> media production solutions.
                </Typography>
            </Box>
            <Box component='img' src='https://i.postimg.cc/158kR355/Comp1-3-ezgif-com-video-to-gif-converter.gif' sx={{
                objectFit: 'contain',
                height: {
                    lg: "160%",
                    md: "160%",
                    sm: "60%",
                    xs: "60%",
                },
                position: 'absolute',
                left: '-10%',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '-1',
                filter: 'opacity(1)',
            }} />
        </Box >
    )
}

export default Bio
import { Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import StaticBG from "./components/BG/StaticBG";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import "./index.css";
import Missing from "./pages/Missing";
import AdminHome from "./pages/admin/AdminHome";
import AdminProjects from "./pages/admin/AdminProjects";
import AdminTeam from "./pages/admin/AdminTeam";
import AdminTeamMember from "./pages/admin/AdminTeamMember";
import EmployeeProfile from "./pages/employee/EmployeeProfile";
import ContactUs from "./pages/general/contactus/ContactUs";
import Home from "./pages/general/home/Home";
import Projects from "./pages/general/projects/Projects";
import ProjectPage from "./pages/general/projects/components/ProjectPage";
import AddTeamMember from "./pages/general/team-member/AddTeamMember";
import EditTeamMember from "./pages/general/team-member/EditTeamMember";
import TeamMember from "./pages/general/team-member/TeamMember";
import Team from "./pages/general/team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import AnimatedLogo from "./components/animated-logo/AnimatedLogo";


declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Gilroy",
    },
  });
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 100, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: "100vw", minHeight: '100vh', display: "flex", fontFamily: "Gilroy", position: "relative" }}>
        <AnimatedLogo />
        <StaticBG />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="home" element={<Home />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects/:id" element={<ProjectPage />} />
            <Route path="team" element={<Team />} />
            <Route path="team/team-member/:id" element={<TeamMember />} />
            <Route path="team/add-member" element={<AddTeamMember />} />
            <Route path="team/edit-member/:id" element={<EditTeamMember />} />
            <Route path="contactus" element={<ContactUs />} />

            <Route element={<RequireAuth allowedRoles={["admin"]} />}>
              <Route path="/admin/home" element={<AdminHome />} />
              <Route path="/admin/projects" element={<AdminProjects />} />
              <Route path="/admin/team" element={<AdminTeam />} />
              <Route
                path="/admin/team-member"
                element={<AdminTeamMember />}
              />
            </Route>

            <Route element={<RequireAuth allowedRoles={["employee"]} />}>
              <Route path="/employee/profile" element={<EmployeeProfile />} />
            </Route>

            <Route path="/home" element={<Home />} />
            <Route path="/categories/:id" element={<Projects />} />
            <Route path="/projects/:id" element={<ProjectPage />} />
            <Route path="/team" element={<Team />} />
            <Route path="team/team-member/:id" element={<TeamMember />} />
            <Route path="team/add-member" element={<AddTeamMember />} />
            <Route path="team/edit-member/:id" element={<EditTeamMember />} />
            <Route path="/contactus" element={<ContactUs />} />

            <Route path="*" element={<Missing />} />

            <Route element={<RequireAuth allowedRoles={["employee"]} />}>
              <Route path="employee/profile" element={<EmployeeProfile />} />
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

export default App;

import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NeedsAuth from "../../../../components/NeedsAuth";
import Fliper from "../../../../components/Fliper";
import { EditRounded } from "@mui/icons-material";
import Dialog from "../../../../components/Dialog";
import { API_URL } from "../../../../data/API";
import useAuth from "../../../../hooks/useAuth";
import Loading from "../../../../components/Loading";
import { Formik } from "formik";
import CustomTextField from "../../../../components/CustomTextField";
import PrimaryBtn from "../../../../components/PrimaryBtn";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import TypingAnimation from "../../../../components/TypingAnimation";
import StaticBG from "../../../../components/BG/StaticBG";
import { Link } from "react-router-dom";

const Welcome = () => {
  const [editDialogShow, setEditDialogShow] = useState<boolean>(false);
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);

  const [skillsResponse, setSkillsResponse] = useState<{ id: string; name: string; status: string }[]>();
  const [skills, setSkills] = useState<any>();
  const [welcomeMessage, setWelcomeMessage] = useState<string>("");
  const [mediaLink, setMediaLink] = useState<string>("");

  useEffect(() => {
    const handleSkills = async () => {
      try {
        const response = await fetch(
          auth.role === "ADMIN" ? `${API_URL}/home/admin` : `${API_URL}/home`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth?.accessToken}`, //
            },
          }
        );
        const result = await response.json();
        console.log(result);
        const fetchedSkills: string[] = [];
        result.home?.skills?.map(
          (skill: { id: string; name: string; status: string }) =>
            fetchedSkills.push(skill.name)
        );
        setSkills(fetchedSkills);
        setSkillsResponse(result.home?.skills);
        setWelcomeMessage(result.home?.description);
        setMediaLink(result.home?.pictureUrl);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    handleSkills();
  }, [auth?.accessToken]);

  const welcomeMessageBtn: any = useRef();

  // const handleSaveEdits = () => {
  //   if (welcomeMessageBtn?.current) {
  //     welcomeMessageBtn.current.click();
  //   }
  // };

  const updateSkillsStatus = async (
    id: string,
    name: string,
    newStatus: boolean
  ) => {
    setSkillsResponse((prevData: any) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, status: newStatus } : item
      )
    );

    try {
      await fetch(`${API_URL}/home/skill/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify({ name: name, status: newStatus }),
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const addNewSkill = async (name: string) => {
    try {
      const response = await fetch(`${API_URL}/home/skill`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify({ name }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add new skill. Status: ${response.status}`);
      }

      console.log("New skill added successfully");
      console.log(response.json())
    } catch (error: any) {
      console.error("Error adding new skill:", error.message);
    }
  };

  const deleteSkill = async (id: string) => {
    try {
      const response = await fetch(`${API_URL}/home/skill/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${auth?.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete skill. Status: ${response.status}`);
      }

      console.log("Skill deleted successfully");
    } catch (error: any) {
      console.error("Error deleting skill:", error.message);
    }
  };

  const updateWelcomeMessage = async (message: string, mediaLink: string) => {
    try {
      await fetch(`${API_URL}/home`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        body: JSON.stringify({
          description: message,
          pictureUrl: mediaLink
        }),
      });
      window.location.reload();
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    window.location.reload();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <><Box
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <Dialog show={editDialogShow}>
        <Formik
          initialValues={{ wMessage: welcomeMessage, mediaLink: mediaLink }}
          onSubmit={(values, { resetForm }) => {
            updateWelcomeMessage(values.wMessage, values.mediaLink);
            setEditDialogShow(false);
            resetForm();
          }}
        >
          {({
            values, touched, errors, handleChange, handleBlur, handleSubmit,
          }) => (
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>

                <Grid item lg={9} md={9} sm={9} xs={9}>
                  <CustomTextField
                    enable={true}
                    isRequired
                    name="wMessage"
                    label="Welcome Message"
                    value={values.wMessage}
                    error={errors.wMessage}
                    touched={touched.wMessage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }} />
                </Grid>
                {/* <Grid item lg={9} md={9} sm={9} xs={9}>
                  <CustomTextField
                    enable={true}
                    isRequired
                    name="mediaLink"
                    label="Media Link"
                    value={values.mediaLink}
                    error={errors.mediaLink}
                    touched={touched.mediaLink}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }} />
                </Grid> */}
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Box sx={{ marginBottom: "0.4rem" }}>
                    <PrimaryBtn title="Save" sx={{ padding: "0.8rem 2rem" }} />
                  </Box>
                </Grid>
              </Grid>
              <PrimaryBtn
                title=""
                sx={{ display: "none" }}
                refProp={welcomeMessageBtn} />
            </Box>
          )}
        </Formik>
        <Box sx={{ marginTop: "1rem" }}>
          <Typography sx={{ marginBottom: "0.5rem", marginLeft: "1rem" }}>
            Categories
          </Typography>
          <Box
            sx={{
              backgroundColor: "black",
              borderRadius: "10px",
              maxHeight: "16rem",
              overflowY: "auto",
              marginBottom: "1rem",
            }}
          >
            {skillsResponse?.map(
              (
                skill: { id: string; name: string; status: string; },
                idx: number
              ) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem",
                    boxSizing: "border-box",
                  }}
                >
                  <Typography>{skill.name}</Typography>
                  <Box sx={{ display: "flex" }}>
                    {skill.status ? (
                      <RemoveRedEyeRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => updateSkillsStatus(
                          `${skill.id}`,
                          `${skill.name}`,
                          false
                        )} />
                    ) : (
                      <VisibilityOffRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => updateSkillsStatus(
                          `${skill.id}`,
                          `${skill.name}`,
                          true
                        )} />
                    )}
                    <DeleteRoundedIcon
                      sx={{ cursor: "pointer", marginLeft: "0.5rem" }}
                      onClick={() => deleteSkill(`${skill.id}`)} />
                  </Box>
                </Box>
              )
            )}{" "}
          </Box>
          <Formik
            initialValues={{ newSkill: "" }}
            onSubmit={(values, { resetForm }) => {
              // updateWelcomeMessage(values.newSkill);
              setEditDialogShow(false);
              addNewSkill(values.newSkill);
              resetForm();
            }}
          >
            {({
              values, touched, errors, handleChange, handleBlur, handleSubmit,
            }) => (
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
                  <Grid item lg={9} md={9} sm={9} xs={9}>
                    <CustomTextField
                      enable={true}
                      isRequired
                      name="newSkill"
                      label="Add New Skill "
                      value={values.newSkill}
                      error={errors.newSkill}
                      touched={touched.newSkill}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width="100%"
                      props={{
                        type: "text",
                      }} />
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={3}>
                    <Box sx={{ marginBottom: "0.4rem" }}>
                      <PrimaryBtn title="add" sx={{ padding: "0.8rem 2rem" }} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Dialog>
      <Box
        sx={{
          width: '95%',
          margin: "2rem auto 0",
          zIndex: "1",
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box sx={{
          padding: {
            lg: "0 6rem 0 4rem",
            md: "0 1rem 0 1rem",
            sm: "1rem",
            xs: "1rem",
          },
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: {
            lg: "flex-start",
            md: "flex-start",
            sm: "center",
            xs: "center",
          },
          // marginRight:{
          //   lg: "3rem",
          //   md: "3rem",
          //   sm: "0",
          //   xs: "0",
          // },
          position: "relative",
          overflow: "hidden",
        }}>

          <NeedsAuth Allowed={["admin"]}>
            <Box onClick={() => setEditDialogShow(!editDialogShow)}>
              <EditRounded sx={{ cursor: "pointer" }} />
            </Box>
          </NeedsAuth>

          <Typography
            sx={{
              fontSize: {
                lg: "5rem",
                md: "5rem",
                sm: "5rem",
                xs: "3rem",
              },
              // fontWeight: "900",
              textAlign: {
                lg: "left",
                md: "left",
                sm: "center",
                xs: "center",
              },
            }}
          >
            Welcome to <Box component='img' src="https://i.postimg.cc/jjgdm6c4/H-LOGO-white-copy.png" sx={{
              width: {
                lg: "35rem",
                md: "35rem",
                sm: "25rem",
                xs: "20rem",
              }, marginBottom: '-1rem'
            }} />
          </Typography>
          <Box sx={{
            fontSize: {
              lg: "3rem",
              md: "3rem",
              sm: "3rem",
              xs: "1.5rem",
            },
            marginTop: '1rem',

          }}>

            <TypingAnimation text={skills} watingTime={2000} />
          </Box>
          <Link to='/contactus'>
            <PrimaryBtn title="KEEP IN TOUCH" sx={{ marginTop: "3rem" }} />
          </Link>
        </Box>
        <Box sx={{
          display: "flex", flexDirection: {
            lg: "column",
            md: "column",
            sm: "column-reverse",
            xs: "column-reverse",
          }, width: {
            lg: "max-content",
            md: "max-content",
            sm: "90%",
            xs: "100%",
          },
          alignItems: "center",
        }}>
          <Typography sx={{
            fontSize: {
              lg: "1.5rem",
              md: "1.5rem",
              sm: "1rem",
              xs: "1rem",
            }, margin: {
              lg: "0 10rem 2rem 0",
              md: "0 2rem 2rem 0",
              sm: "5rem 0rem 2rem",
              xs: "5rem 0rem 2rem",
            },
            width: "max-content"
          }}>

            <span style={{ display: "block", fontSize: "inherit", width: "max-content" }}>
              <span style={{ fontWeight: "bold" }}>" </span>THE <span style={{ fontWeight: "bold" }}>PLUS</span> YOU
            </span>
            <Typography sx={{
              display: "block", fontSize: "inherit", width: "max-content", marginLeft: {
                lg: "8rem",
                md: "8rem",
                sm: "5rem",
                xs: "5rem",
              },
            }}>
              ARE <span style={{ fontWeight: "bold" }}>LOOKING</span> FOR<span style={{ fontWeight: "bold" }}> "</span>
            </Typography>
          </Typography>
          <Box component='img' src='https://i.postimg.cc/c4ftbpbr/welcome-Img.png' sx={{
            position: "absolute", top: 0, right: '0', zIndex: '-1', height: '95vh', filter: {
              lg: "opacity(0.6)",
              md: "opacity(0.6)",
              sm: "opacity(0.2)",
              xs: "opacity(0.2)",
            }
          }} />
        </Box>
        {/* <Box sx={{
          width: {
            lg: "70%",
            md: "70%",
            sm: "100%",
            xs: "100%",
          },
        }}>
          {mediaLink.includes("video") || mediaLink.includes("drive") || mediaLink.includes("facebook")? (
            <Box
              sx={{
                height: {
                  lg: "100%",
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                },
                width: {
                  lg: "auto",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <iframe
                title='welcome to HPlus'
                src={mediaLink}
                style={{
                  minWidth: "100%",
                  minHeight: "100%",
                  borderRadius: '5px',
                  aspectRatio: "640 / 360",
                  borderTopRightRadius: "0rem",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          ) : (
            <Box
              component="img"
              src={mediaLink}
              sx={{
                maxHeight: "100%",
                borderTopRightRadius: "8rem",
                filter: "grayscale(1)",
                transition: "0.3s ease-out",
                "&:hover": {
                  filter: "grayscale(0)",
                },
              }}
            />
          )}
        </Box> */}
      </Box>
    </Box></>
  );
};

export default Welcome;

import React from 'react'
import LiveBackground from './live/LiveBackground'
import EventsBackground from './events/EventsBackground';
import SportsBackground from './sports/SportsBackground';

const CategoryBackground = ({ CategoryName }: { CategoryName: any }) => {
    if (CategoryName === 'Live Streaming') {
        return <LiveBackground />;
    } else if (CategoryName === 'Events') {
        return <EventsBackground />;
    } else if (CategoryName === 'Sports') {
        return <SportsBackground />;
    } else {
        return null;
    }
}
export default CategoryBackground

import { Box } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import Button from '@mui/material/Button';

interface SwipperType {
  children: React.ReactNode;
  className: string;
  timeOut: number;
  NumOfElements: number;
  mode?: string;
  length: number;
}

export const controller = (
  children: any,
  setScroll: Function,
  setI: Function,
  i: number,
  NumOfElements: number,
  mode: string | undefined,
  windowWidth: number
) => {
  let content: ReactElement[] = [];
  for (
    let l = 0;
    l < children.length - ((windowWidth < 900 ? 1 : NumOfElements) - 1);
    l++
  ) {
    content.push(
      <Button
        sx={{
          borderRadius: '50%',
          backgroundColor:
            mode === 'light'
              ? i === l
                ? '#ffffffdd'
                : '#ffffff50'
              : i === l
                ? '#000000dd'
                : '#00000050',

          maxWidth: '1rem',
          height: '1rem',
          cursor: 'pointer',
          margin: '0 .5rem ',
          minWidth: 'auto',
          "&:hover": {
            backgroundColor:
              mode === 'light'
                ? i === l
                  ? '#ffffffdd'
                  : '#ffffff50'
                : i === l
                  ? '#000000dd'
                  : '#00000050',
          }
        }}
        onClick={() => {
          setScroll(l * (-(windowWidth < 900? 90 : 45) / (windowWidth < 900 ? 1 : NumOfElements)));
          setI(l);
        }}
        aria-label='Select Services to show'
      />
    );
  }
  return content;
};

export default function Swipper({
  children,
  className,
  timeOut,
  NumOfElements,
  mode,
  length,
}: SwipperType) {
  const [windowWidth, setWindowWidth] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [Scroll, setScroll] = useState(0);
  const [i, setI] = useState(0);

  const [isMouseHeld, setIsMouseHeld] = useState<boolean>(false);
  const [startPoint, setStartPoint] = useState<number>(0);
  const [direction, setDirection] = useState<string>('');

  useEffect(() => {
    setMounted(true);
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setScroll(Scroll - (windowWidth < 900? 90 : 45) / (windowWidth < 900 ? 1 : NumOfElements));
      if (i < length - (windowWidth < 900 ? 1 : NumOfElements)) {
        setScroll(Scroll - (windowWidth < 900? 90 : 45) / (windowWidth < 900 ? 1 : NumOfElements));
        setI(i + 1);
      } else {
        setScroll(
          Scroll +
          ((length - (windowWidth < 900 ? 1 : NumOfElements)) * (windowWidth < 900? 90 : 45)) /
          (windowWidth < 900 ? 1 : NumOfElements)
        );
        setI(0);
      }
    }, timeOut * 3);

    return () => clearInterval(interval);
  }, [Scroll, i, timeOut, length, NumOfElements, windowWidth]);

  useEffect(() => {
    setScroll(0);
    setI(0);
  }, [windowWidth]);

  if (!mounted) {
    return null;
  }

  const reportWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.onresize = reportWindowSize;

  const touchSwipe = (e: any) => {
    if (isMouseHeld) {
      if (e.targetTouches[0].clientX - startPoint > 0) {
        setDirection('right');
      } else {
        setDirection('left');
      }
    }
  };

  const handleMouseUp = () => {
    setIsMouseHeld(false);
    if (direction === 'right' && i !== 0) {
      setScroll(Scroll + (windowWidth < 900? 90 : 45));
      setI(i - 1);
    } else if (
      direction === 'left' &&
      i !== length - (windowWidth < 900 ? 1 : NumOfElements)
    ) {
      setScroll(Scroll - (windowWidth < 900? 90 : 45));
      setI(i + 1);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <KeyboardArrowLeftRoundedIcon
        sx={{
          position: 'absolute',
          backgroundColor:"#000000aa",
          borderRadius:'50%',
          left: { lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem' },
          top: 'calc(50%  - 1.5rem)',
          transform: 'translateY(-50%)',
          fontSize: { lg: '3rem', md: '3rem', sm: '2rem', xs: '2rem' },
          color: mode === 'light' ? 'white' : 'black',
          cursor: i === 0 ? 'default' : 'pointer',
          opacity: i === 0 ? '0.4' : '1',
          zIndex: '1000',
        }}
        onClick={() =>
          i !== 0
            ? (setScroll(Scroll + (windowWidth < 900? 90 : 45) / (windowWidth < 900 ? 1 : NumOfElements)),
              setI(i - 1))
            : null
        }
      />
      <Box
        sx={{
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            marginLeft: {
              lg: '-0.2rem',
              md: '-0.2rem',
              sm: '0rem',
              xs: '0rem',
            },
            transition: '0.3s',
            display: 'flex',
            width: 'fit-content',
            transform: {
              lg: `translateX(${Scroll}vw)`,
              md: `translateX(${Scroll}vw)`,
              sm: `translateX(${Scroll}vw)`,
              xs: `translateX(${Scroll}vw)`,
            },
          }}
          onTouchStart={(e: any) => {
            setStartPoint(e.targetTouches[0].clientX);
            setIsMouseHeld(true);
          }}
          onTouchMove={(e) => touchSwipe(e)}
          onTouchEnd={() => handleMouseUp()}

        // onTouch={() => console.log("ahhhhh")}
        >
          {children}
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '2rem',
            margin: '0 auto',
            justifyContent: 'center',
            marginTop: '2rem',
            color: 'black',
          }}
        >
          {controller(
            children,
            setScroll,
            setI,
            i,
            NumOfElements,
            mode,
            windowWidth
          )}
        </Box>
      </Box>
      <KeyboardArrowRightRoundedIcon
        sx={{
          position: 'absolute',
          backgroundColor:"#000000aa",
          borderRadius:'50%',
          right: { lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem' },
          top: 'calc(50%  - 1.5rem)',
          transform: 'translateY(-50%)',
          fontSize: { lg: '3rem', md: '3rem', sm: '2rem', xs: '2rem' },
          color: mode === 'light' ? 'white' : 'black',
          cursor:
            i === length - (windowWidth < 900 ? 1 : NumOfElements)
              ? 'default'
              : 'pointer',
          opacity:
            i === length - (windowWidth < 900 ? 1 : NumOfElements)
              ? '0.4'
              : '1',
          zIndex: '1000',
        }}
        onClick={() =>
          i !== length - (windowWidth < 900 ? 1 : NumOfElements)
            ? (setScroll(Scroll - (windowWidth < 900? 90 : 45) / (windowWidth < 900 ? 1 : NumOfElements)),
              setI(i + 1))
            : null
        }
      />
    </Box>
  );
}

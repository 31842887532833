import { Box } from '@mui/material'
import React from 'react'

const ClientCard = ({ imgSrc }: { imgSrc: string }) => {
    return (
        <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
            backgroundColor: 'white',
            borderRadius: '10px',
            aspectRatio: '2 / 1.5',
            overflow: 'hidden',
        }}>
            <Box component='img' src={imgSrc} sx={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
            }} />
        </Box>
    )
}

export default ClientCard
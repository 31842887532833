import { Box, Grid } from "@mui/material";
import React from "react";
import ImageOnlyCard from "../Cards/ImageOnlyCard";

const ImagesSection = ({ imgs }: { imgs: string[] }) => {
  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      {imgs.map((img: string, idx: number) => (
        <Grid
          key={idx}
          item
          lg={imgs.length === 1 ? 10 : 5}
          md={imgs.length === 1 ? 10 : 5}
          sm={imgs.length === 1 ? 12 : 6}
          xs={imgs.length === 1 ? 12 : 12}
        >
          <ImageOnlyCard UUID={img} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImagesSection;

import { Grid } from '@mui/material'
import React from 'react'
import Count from '../../../../components/Count'

const HomeCount = () => {
    return (
        <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Count initialValue={0} finalValue={1000} duration={5000} text={'Photos'} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Count initialValue={0} finalValue={500} duration={5000} text={'Customers'}/>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
                <Count initialValue={0} finalValue={1000} duration={5000} text={'Drone Minutes'}/>
            </Grid>
        </Grid>
    )
}

export default HomeCount
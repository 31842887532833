import { Box } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import React, { useState } from "react";
import NeedsAuth from "../../../../../components/NeedsAuth";
import CategoryForm from "./CategoryForm";
import Dialog from "../../../../../components/Dialog";

const AddCategory = () => {
  const [editDialogShow, setEditDialogShow] = useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <Box
          sx={{
            height: "1px",
            width: "100%",
            zIndex: "1",
            backgroundColor: "white",
          }}
        ></Box>
        <Box
          sx={{
            borderRadius: "50%",
            width: "1rem",
            height: "1rem",
            padding: "1rem",
            backgroundColor: "transparent",
            border: "2px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1",
            cursor: "pointer",
          }}
          onClick={() => setEditDialogShow(!editDialogShow)}
        >
          <AddRoundedIcon sx={{ color: "white" }} />
        </Box>
        <Box
          sx={{
            height: "1px",
            width: "100%",
            zIndex: "1",
            backgroundColor: "white",
          }}
        ></Box>
      </Box>
      <NeedsAuth Allowed={["admin"]}>
        <Dialog show={editDialogShow}>
          <CategoryForm />
        </Dialog>
      </NeedsAuth>
    </>
  );
};

export default AddCategory;

import React from "react";

const PrimaryBtn = ({
  title,
  sx,
  refProp,
  type,
  onClick,
  disabled,
}: {
  title: string;
  sx?: any;
  refProp?: any;
  type?: any;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <button
      onClick={handleClick}
      ref={refProp}
      type={type ? type : "submit"}
      style={{
        opacity: disabled ? 0.8 : "1",
        marginTop: "1rem",
        background: "linear-gradient(45deg, rgba(1,0,16,1) 0%, rgba(80,7,0,1) 100%)",
        color: "white",
        padding: "1rem 2rem",
        textDecoration: "none",
        border: "0",
        cursor: disabled ? "default" : "pointer",
        borderRadius: "5px",
        minWidth: "max-content",
        ...sx,
      }}
    >
      {title}
    </button>
  );
};

export default PrimaryBtn;

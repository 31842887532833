import React, { useRef, useEffect, useState } from "react";

const CanvasWithImage = () => {
  // const canvasRef = useRef(null);

  // useEffect(() => {
  //   const canvas: any = canvasRef.current;
  //   const context: any = canvas.getContext("2d");

  //   const image = new Image();
  //   image.src = "https://i.postimg.cc/HsY5B0hF/bg.jpg";

  //   image.onload = () => {
  //     // Draw the image on the canvas once it's loaded
  //     context.drawImage(image, 0, 0, canvas.width, canvas.height);
  //   };
  // }, []);

  const [height, setHeight] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) { // xs
        setHeight("auto");
      } else if (window.innerWidth <= 767) { // sm
        setHeight("auto");
      } else {
        setHeight("100%");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <canvas
      // ref={canvasRef}
      width={1920}
      // height={10800}
      style={{
        background: "url(https://i.postimg.cc/tT0vjNSt/bg.png)",

        backgroundSize: "cover",

        overflow: "hidden",
        position: "absolute",
        width: "100%",
        minHeight: "100%",
        height: height,
        zIndex: "-1",
        opacity:"0.8"
      }}
    />
    
  );
};

export default CanvasWithImage;

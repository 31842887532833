import React from 'react'

const LogoPart2 = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 1000" style={{
            width: '100%',
            height: '100%',
        }}>
            <defs>
                <style>
                    {`.cls-1 {
                        fill: #fff;
                    stroke-width: 0px;
      }`}
                </style>
            </defs>
            <path className="cls-1" d="M1000,333.3v333.4h-333.3v333.3h-282.3c-28.2,0-51.1-22.9-51.1-51.1v-282.2H120c-22.1,0-41.7-14.2-48.5-35.2L0,412.1v-78.8h333.3V51C333.3,22.9,356.2,0,384.4,0h282.3v333.3h333.3Z" />
        </svg>
    )
}

export default LogoPart2
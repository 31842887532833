import { Box, Typography } from "@mui/material";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import PinterestIcon from "@mui/icons-material/Pinterest";
// import XIcon from "@mui/icons-material/X";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";

const TeamMemberHeader = ({
  name,
  position,
  quote,
  imgSrc,
}: {
  name: string;
  position: string;
  quote: string;
  imgSrc: string;
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_S3_BUCKET;
    if (imgSrc !== null) {
      s3.getSignedUrl(
        "getObject",
        { Bucket: bucketName, Key: imgSrc, Expires: 60 },
        (err, url) => {
          if (err) {
            console.error("Error generating signed URL:", err);
            return;
          }
          setImageUrl(url);
        }
      );
    }
  }, []);
  return (
    <Box
      sx={{
        width: "70%",
        margin: "0 auto",
        height: {
          lg: "20rem",
          md: "20rem",
          sm: "auto",
          xs: "auto",
        },
        backgroundColor: "#00000050",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        backdropFilter: "blur(5px)",
        display: "flex",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
        borderBottom: "2px solid red",
      }}
    >
      <Box sx={{
        overflow: "hidden",
        maxHeight: "100%",
        width: {
          lg: "35%",
          md: "35%",
          sm: "100%",
          xs: "100%",
        },
      }}>

        <Box
          component="img"
          loading="lazy"
          src={
            imgSrc !== null
              ? imageUrl ?? "https://i.postimg.cc/y6jCwkfY/user.jpg"
              : "https://i.postimg.cc/y6jCwkfY/user.jpg"
          }
          sx={{
            // aspectRatio: "1/1",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          marginLeft: {
            lg: "1rem",
            md: "1rem",
            sm: "0rem",
            xs: "0rem",
          },
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box>
          <Box sx={{
            display: 'flex', fontSize: {
              lg: "2.5rem",
              md: "2.5rem",
              sm: "1.3rem",
              xs: "1.3rem",
            }, flexDirection: {
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            textAlign: "center",
          }}>

            <Typography
              sx={{
                fontSize: "inherit",
                color: "white",
                position: "relative",
              }}
            >
              Hello I'm&nbsp;
            </Typography>
            <Typography
              sx={{
                fontSize: "inherit",
                fontWeight: "600",
                color: "white",
              }}
            >
              {name}
            </Typography>
          </Box>
          <Typography
            sx={{
              display: "flex", fontSize: {
                lg: "1.2rem",
                md: "1.2rem",
                sm: "1rem",
                xs: "1rem",
              }, justifyContent: {
                lg: "flex-start",
                md: "flex-start",
                sm: "center",
                xs: "center",
              }, color: "#aaa", marginBottom: '1rem'
            }}
          >
            a {position} at H+ Universe
          </Typography>
        </Box>
        {/* <Box sx={{ display: "flex", color: "white" }}>
          <Typography sx={{ color: "#aaa", marginRight: "0.5rem" }}>
            Social Accounts |{" "}
          </Typography>
          <FacebookIcon sx={{ margin: "0 0.5rem 0 0", cursor: "pointer" }} />
          <InstagramIcon sx={{ margin: "0 0.5rem 0 0", cursor: "pointer" }} />
          <PinterestIcon sx={{ margin: "0 0.5rem 0 0", cursor: "pointer" }} />
          <YouTubeIcon sx={{ margin: "0 0.5rem 0 0", cursor: "pointer" }} />
          <LinkedInIcon sx={{ margin: "0 0.5rem 0 0", cursor: "pointer" }} />
          <XIcon sx={{ margin: "0 0.5rem 0 0", cursor: "pointer" }} />
        </Box> */}
      </Box>
    </Box>
  );
};

export default TeamMemberHeader;

import React from "react";
import CustomBox from "../CustomBox";
import CustomTextField from "../CustomTextField";
import { Formik } from "formik";
// import * as Yup from "yup";
import { Box } from "@mui/material";

const Signup = () => {
  return (
    <CustomBox title="Sign Up">
      <Formik
        initialValues={{ userName: "", passwrod: "", validatePassword: "" }}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <CustomTextField
              enable={true}
              isRequired
              name="userName"
              label="User Name"
              value={values.userName}
              error={errors.userName}
              touched={touched.userName}
              onChange={handleChange}
              onBlur={handleBlur}
              width="100%"
              props={{
                type: "text",
              }}
            />
            <CustomTextField
              enable={true}
              isRequired
              name="passwrod"
              label="passwrod"
              value={values.passwrod}
              error={errors.passwrod}
              touched={touched.passwrod}
              onChange={handleChange}
              onBlur={handleBlur}
              width="100%"
              props={{
                type: "password",
              }}
            />
            <CustomTextField
              enable={true}
              isRequired
              name="validatePassword"
              label="validate Password"
              value={values.validatePassword}
              error={errors.validatePassword}
              touched={touched.validatePassword}
              onChange={handleChange}
              onBlur={handleBlur}
              width="100%"
              props={{
                type: "password",
              }}
            />
            <button
              type="submit"
              style={{
                marginTop: "1rem",
                backgroundColor: "red",
                color: "white",
                padding: "1rem 2rem",
                textDecoration: "none",
                border: "0",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              Sign Up
            </button>
          </Box>
        )}
      </Formik>
    </CustomBox>
  );
};

export default Signup;

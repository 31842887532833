import { Box, Typography } from '@mui/material'
import React from 'react'

const LiveIcon = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // width: '4rem',
            borderRadius: '5px',
            backgroundColor: "#fe034e",
            position: 'absolute',
            top: '3rem',
            right: '-2.6rem',
            transform: 'translate(-50%,-50%)',
            padding: '0.6rem 1.5rem',
            boxSizing: 'border-box',
            zIndex:'9999'
        }}>
            <Box
                sx={{
                    width: "0.8rem",
                    height: '0.8rem',
                    aspectRatio: "1 / 1",
                    backgroundColor: 'white',
                    borderRadius: "50%",
                    mr: 2,
                    animation: "fade-in 2s infinite",
                    "@keyframes fade-in": {
                        "0%": { opacity: 0 },
                        "50%": { opacity: 1 },
                        "100%": { opacity: 0 },
                    },
                }}
            />
            <Typography sx={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}>
                LIVE
            </Typography>
        </Box>
    )
}

export default LiveIcon
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import StaticBG from "../../../components/BG/StaticBG";
import NavBar from "../../../components/NavBar/NavBar";

import OurData from "./components/ourdata/OurData";
import Form from "./components/form/Form";
import PageHeader from "../../../components/PageHeader";
import BG from "../../../components/BG/BG";
import Loading from "../../../components/Loading";
import { API_URL } from "../../../data/API";

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/contact-us`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Grid
        container
        sx={{ width: "95%", margin: "2rem auto 1rem", zIndex: "5" }}
      >
        <PageHeader Header="Keep In Touch!" />
        <Box sx={{ marginTop: '2rem', width: '100%' }}></Box>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ marginRight: "2rem", marginBottom: "2rem", height: "100%" }}>
            <OurData
              address={data.data.address}
              email={data.data.email}
              phone={data.data.phone}
              embeddedMapLink="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2%20Gawad%20Housny,%20As%20Sahah,%20Abdeen,%20Cairo%20Governorate%204280142+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              mapLink="https://www.google.com/maps?ll=30.04618,31.243031&z=14&t=m&hl=en&gl=US&mapclient=embed&q=2+Gawad+Housny+As+Sahah+Abdeen+Cairo+Governorate+4280142"
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Form />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;

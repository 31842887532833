import { Box } from '@mui/material'
import React from 'react'

const SportsBackground = () => {
    return (
        <Box sx={{
            width: '97vw',
            marginX: 'auto',
            position: 'absolute',
            left: 'calc(-48.5vw + 50%)',

        }} data-aos="fade-up">
            <Box sx={{
                position: 'relative', width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: '-1',
            }}>
                <Box sx={{
                    width: '100%',
                    height: '20vh',
                    background: `radial-gradient(circle, #03fe4e , #00000000 80%)`,
                    filter: 'blur(10px)',
                    position: 'absolute',
                    top: '0',

                }} />
                <Box sx={{
                    width: '99vw',
                    left: 'calc(-49.5vw + 50%)',
                    height: '140vh',
                    backgroundColor: 'black',
                    filter: 'blur(30px)',
                    position: 'absolute',
                    top: '0',
                    zIndex: '0',
                    overflow: 'hidden',
                }} >
                    <Box sx={{
                        width: '50rem',
                        height: '50rem',
                        background: `radial-gradient(circle, #03fe4e40 , #00000000 80%)`,
                        filter: 'blur(20px)',
                        position: 'absolute',
                        top: '0',
                        right: '-10rem',
                        zIndex: '1',
                    }} />
                </Box>
            </Box>
        </Box>
    )
}

export default SportsBackground
import { Box } from '@mui/material'
import React from 'react'

const Corner = ({ pos }: { pos?: 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight' }) => {
    let rotation = 0;

    switch (pos) {
        case 'TopLeft':
            rotation = 0;
            break;
        case 'TopRight':
            rotation = 90;
            break;
        case 'BottomLeft':
            rotation = -90;
            break;
        case 'BottomRight':
            rotation = 180;
            break;
        default:
            rotation = 0;
            break;
    }

    return (
        <Box sx={{
            width: '5rem',
            aspectRatio: '1 / 1',
            transform: `rotate(${rotation}deg)`,
            // transformOrigin: 'top left',
        }}>
            <Box sx={{ height: '3px', width: '100%', backgroundColor: 'white' }} />
            <Box sx={{ width: '3px', height: '100%', backgroundColor: 'white' }} />
        </Box>
    )
}

export default Corner
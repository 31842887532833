import { Box, Typography } from "@mui/material";
import React from "react";

const PageHeader = ({ Header, sx }: { Header: string; sx?: any }) => {
  return (
    <Box
      sx={{
        color: "white",
        width: "100%",
        textAlign: "center",
        marginBottom: "0.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <Box component='img' src='https://i.postimg.cc/yYzXFYCX/PlusIcon.png' sx={{
        height: {
          lg: "2.5rem",
          md: "1.5rem",
          sm: "1.5rem",
          xs: "1.5rem",
        }, marginRight: '1rem'
      }}/>
      <Typography sx={{
        fontSize: {
          lg: "3rem",
          md: "2rem",
          sm: "2rem",
          xs: "2rem",
        }, fontWeight: "600"
      }}>
        {Header.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default PageHeader;

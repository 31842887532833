import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import NavBar from "./NavBar/NavBar";

const Layout = () => {
  return (
    <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ height: '15vh' }}>

        <NavBar />
      </Box>
      <Outlet />
    </Box>
  );
};

export default Layout;

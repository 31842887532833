import React, { useEffect, useState } from 'react'
import LogoPart1 from './LogoPart1'
import { Box } from '@mui/material'
import LogoPart2 from './LogoPart2'

const AnimatedLogo = () => {

    const [isVisible, setIsVisible] = useState(true);
    const [translatePart1, setTranslatePart1] = useState(0);
    const [translate, setTranslate] = useState(0);
    const [rotate, setRotate] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const threshold = 100; // You can adjust this threshold as needed
            if (window.scrollY > threshold) {
                setIsVisible(false);
                setTranslate(-50);
                setTranslatePart1(0.65);
                setRotate(45);
            } else {
                setIsVisible(true);
                setTranslate(0);
                setTranslatePart1(0);
                setRotate(0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Box sx={{
            position: 'fixed', width: '6rem', height: '6rem', display: 'flex', padding: '1.5rem', boxSizing: 'border-box',
            bottom: '2rem',
            right: '2rem',
            zIndex: "10000",
            // background: 'linear-gradient(to bottom right, #DC2D0290, #16025990)',
            backgroundColor: '#00000080',
            backdropFilter: "blur(5px)",
            borderRadius: '50%',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',

        }}
            onClick={scrollToTop}

        >
            <Box sx={{
                width: '2rem',
                height: '2.1rem',
                transition: '0.5s',
                // transitionDelay: '0.2s
                left: '1.1rem',
                top: '50%',
                position: 'absolute',
                transform: `rotate(${rotate}deg) translateY(-50%) translateX(-${translatePart1}rem)`
            }}
            >
                <LogoPart1 />
            </Box>

            <Box sx={{
                width: '2.25rem',
                height: '2.1rem',
                transition: '0.5s',
                right: '1.1rem',
                top: '50%',

                // transitionDelay: '0.2s',
                transform: `rotate(-${rotate}deg) translateY(-50%) translateX(${translatePart1}rem)`,
                opacity: isVisible ? 0 : 1,
                position: 'absolute',
            }}>
                <LogoPart1 />
            </Box>

            <Box sx={{
                width: '35%',
                right: '1.5rem',
                height: '2rem',
                top: '50%',
                transition: '0.5s',
                position: 'absolute',
                opacity: isVisible ? 1 : 0, // Set opacity based on visibility
                transform: `translateX(${translate}%) translateY(-50%)  `
            }}>
                <LogoPart2 />
            </Box>

        </Box>
    )
}

export default AnimatedLogo
import { Box } from "@mui/material";
import React from "react";
import SectionBtn from "./SectionBtn";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import VideocamIcon from "@mui/icons-material/Videocam";
const AddProjectSection = ({
  projectID,
  onClick,
  getData,
}: {
  projectID: string;
  onClick: () => void;
  getData: any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{ width: "100%", height: "3px", backgroundColor: "#66666677" }}
      ></Box>
      <Box sx={{ display: "flex" }} >
        <SectionBtn
          btnName="Text"
          btnIcon={<TextFieldsIcon />}
          left
          projectID={projectID}
          getData={getData}
          onClick={onClick}
        />
        <SectionBtn
          btnName="Images"
          btnIcon={<ImageIcon />}
          mid
          projectID={projectID}
          getData={getData}
          onClick={onClick}
        />
        <SectionBtn
          btnName="Video"
          btnIcon={<VideocamIcon />}
          right
          projectID={projectID}
          getData={getData}
          onClick={onClick}
        />
      </Box>
      <Box
        sx={{ width: "100%", height: "3px", backgroundColor: "#66666677" }}
      ></Box>
    </Box>
  );
};

export default AddProjectSection;

import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/Loading";
import { API_URL } from "../../../../../data/API";
import ThumbnailProjectCard from "../../../projects/components/Cards/ThumbnailProjectCard";
import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const HomeMembers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/member`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result.members);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  console.log(data);
  if (loading) {
    return <Loading />;
  }
  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        filter: "grayscale(1)",
      }}
    >
      {" "}

      {data.map((member: any) => {
        const validNames = ["Ahmed Hesham", "Mohamed Badry", "Mohamed Hussien", "Hamsa Mo. Gharib", "Hassan Nageeb"];
        if (validNames.includes(member.name)) {
          return (
            <Grid item lg={2} md={4} sm={6} xs={6} key={member.id}>
              <Link to={`/team/team-member/${member.id}`}>
                <ThumbnailProjectCard
                  projectImageSrc={member.pictureUrl}
                  projectName={member.name}
                  sx={{
                    height: '25rem',
                  }}
                />
              </Link>
            </Grid>
          );
        }
        return null; // Return null for members with invalid names
      })}
      <Grid item lg={2} md={4} sm={6} xs={6} data-aos='fade-up'>
        <Link to={`/team`} style={{ textDecoration: "none" }}
        >
          <Box sx={{
            backgroundColor: "#1f1f1f90",
            padding: "3rem 2rem",
            boxSizing: "border-box",
            backdropFilter: "blur(5px)",
            height: '25rem',
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            color: 'white',
            fontSize: "1.5rem",
            fontWeight: "bold",
            userSelect: 'none',
            cursor: 'pointer',

          }}>
            {/* <Box component='img' src='https://api.vitetime.co/uploads/402107790_STATIC_NOISE_400_d4620d9ade.gif' sx={{
              position: 'absolute',
              bottom: "0",
              right: "0",
              // height: "80%",
              zIndex: '-1',
              filter: "opacity(0.4)",
              transition: '0.3s',
              cursor: "pointer",
              userSelect: 'none',
              width: '100%',
              height: '100%',
              "&:hover": {
                filter: "opacity(0.2)",

              }

            }} /> */}
            View All
          </Box>
        </Link>
      </Grid>
    </Grid>
  );
};

export default HomeMembers;

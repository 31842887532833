import React, { useState } from "react";
import CustomBox from "../CustomBox";
import CustomTextField from "../CustomTextField";
import { Formik } from "formik";
// import * as Yup from "yup";
import { Box, Typography } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../data/Paths";
import NavBar from "../NavBar/NavBar";
import { API_URL } from "../../data/API";
const Login = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [err, setErr] = useState<string>("");
  const handleSubmit = async (values: { email: string; password: string }) => {
    const email = values.email;
    const password = values.password;
    try {
      const response: any = await fetch(`${API_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw errorData;
      }

      setErr("");
      const responseObject = await response.json();

      const accessToken = await responseObject?.access_token;
      const role = await responseObject?.user?.userType;
      setAuth({ ...values, accessToken, role });

      switch (role.toLowerCase()) {
        case "admin":
          navigate(Paths.general.home, { replace: true });
          break;
        case "employee":
          navigate(Paths.employee.teamMember, { replace: true });
          break;

        default:
          navigate(Paths.general.home, { replace: true });
          break;
      }

      localStorage.setItem("token", accessToken);
      localStorage.setItem("role", role);

      return responseObject;
    } catch (error: any) {
      console.log(error.message);
      setErr(Array.isArray(error.message) ? error.message[0] : error.message);
    }
  };

  return (
    <>
      <NavBar />
      <Box sx={{ width: '100%', height: '100vh', position: 'relative' }}>

        <Box
          sx={{
            width: {
              lg: "auto",
              md: "auto",
              sm: "100%",
              xs: "100%",
            },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          {auth.accessToken ? (
            <Typography sx={{ color: "white", textAlign: "center" }}>
              Yor already logged in, Log in another Account?
            </Typography>
          ) : null}
          <CustomBox title="Log in">
            <Box
              sx={{
                display: err === "" ? "none" : "block",
                padding: "1rem",
                marginBottom: "1rem",
                borderRadius: "5px",
                boxSizing: "border-box",
                width: "100%",
                backgroundColor: "#ff000033",
                color: "white",
              }}
            >
              {err}
            </Box>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                  <CustomTextField
                    enable={true}
                    isRequired
                    name="email"
                    label="Email"
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "text",
                    }}
                  />
                  <CustomTextField
                    enable={true}
                    isRequired
                    name="password"
                    label="password"
                    value={values.password}
                    error={errors.password}
                    touched={touched.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width="100%"
                    props={{
                      type: "password",
                    }}
                  />

                  <button
                    type="submit"
                    style={{
                      marginTop: "1rem",
                      backgroundColor: "red",
                      color: "white",
                      padding: "1rem 2rem",
                      textDecoration: "none",
                      border: "0",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                  >
                    Login
                  </button>
                </Box>
              )}
            </Formik>
          </CustomBox>
        </Box>
      </Box>
    </>
  );
};

export default Login;

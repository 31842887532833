import { Box } from "@mui/material";
import React from "react";
import StaticBG from "../../../components/BG/StaticBG";
import NavBar from "../../../components/NavBar/NavBar";
import MembersContainer from "./components/MembersContainer";
import PageHeader from "../../../components/PageHeader";
import SummaryCards from "./components/SummaryCards";

const Team = () => {
  return (
    <>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Box sx={{ width: "95%", margin: "2rem auto 1rem" }}>
        <SummaryCards />
        <PageHeader Header="Meet Our Team" />
        <MembersContainer />
      </Box>
    </>
  );
};

export default Team;

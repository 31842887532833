import { Grid } from '@mui/material'
import React from 'react'
import ClientCard from './ClientCard'

const Clients = () => {

    const clients: string[] = [
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcawMbIJk18FCy_72FioWwWAYFXOyqQz1LWlAMjlibRA&s',
        'https://i.postimg.cc/1Rb298D4/273788410-5107398672631605-695647056169805857-n.jpg',
        'https://i.postimg.cc/wTJP39k2/295277541-419730620176717-2831457884356144099-n.jpg',
        'https://i.postimg.cc/pr5fGXd6/310346572-494351996036644-7896607659119655985-n.jpg',
        'https://i.postimg.cc/hhF9HWzP/338915040-1626830127742874-6824457115891395488-n.jpg',
        'https://i.postimg.cc/bwxTfJRX/242553076-106906958416713-6419107257090422317-n.jpg',
        'https://i.postimg.cc/5tpSjRGn/347586278-167782036253530-5894797832053697222-n.jpg',
        'https://i.postimg.cc/tg9zqjrs/378134544-614539067556549-8587030003730685921-n.jpg',
        'https://i.postimg.cc/7P5j83Gp/395420137-756749333161389-9078546923228423004-n.jpg',
        'https://i.postimg.cc/Sss79Gbq/422092536-891295096329613-3804219915953134086-n.jpg',
        'https://i.postimg.cc/pryH33N7/13315571-1103174293083263-2010779113519614482-n.png',
        'https://i.postimg.cc/28n0j2PT/EVO-01.png',

    ]

    return (
        <Grid container spacing={2} columns={20} sx={{ justifyContent: 'center' }}>
            {
                clients.map((client: string, idx: number) => (
                    <Grid item lg={4} md={5} sm={5} xs={10} key={idx} data-aos="fade-up" data-aos-delay={`${idx % 5 * 200}`}
                    >
                        <ClientCard imgSrc={client} />
                    </Grid>
                )
                )
            }

        </Grid>
    )
}

export default Clients
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';

interface AnimatedTitleProps {
  title: string;
}

const AnimatedTitle: React.FC<AnimatedTitleProps> = ({ title }) => {
  const [width, setWidth] = useState(0);
  const [translateX, setTranslateX] = useState(-100);
  const animatedTitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const startAnimation = () => {
      const timer = setTimeout(() => {
        setWidth(100);
        setTranslateX(0);
      }, 1000); // Start animation after 1 second

      return () => clearTimeout(timer); // Cleanup on unmount
    };

    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0) {
          startAnimation();
          observer?.disconnect(); // Stop observing once the element is visible
        }
      });
    });

    if (animatedTitleRef.current) {
      observer.observe(animatedTitleRef.current);
    }

    return () => {
      observer?.disconnect(); // Cleanup observer on unmount
    };
  }, []);

  return (
    <Box sx={{ marginY: '2rem', overflowX: 'hidden' }} ref={animatedTitleRef}>
      <Typography
        sx={{
          color: '#aaa',
          cursor: 'default',
          transition: 'transform 1.5s ease-in-out, color 0.3s ease-in-out',
          transform: `translateX(${translateX}%)`,
          fontSize: '1rem',
          '&:hover': {
            color: 'white',
          },
        }}
      >
        {title.toUpperCase()}
      </Typography>
      <Box
        sx={{
          width: `${width}%`,
          transition: 'width 0.8s ease-in-out',
          height: '1px',
          backgroundColor: '#aaa',
        }}
      />
    </Box>
  );
};

export default AnimatedTitle;

import { Box } from '@mui/material'
import React from 'react'
import Corner from '../live/Corner'
import LiveIcon from '../live/LiveIcon'

const EventsBackground = () => {
    return (
        <Box sx={{
            width: '97vw',
            marginX: 'auto',
            position: 'absolute',
            left: 'calc(-48.5vw + 50%)',

        }}data-aos="fade-up">
            <Box sx={{
                position: 'relative', width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: '-1',
            }}>
                <Box sx={{
                    width: '100%',
                    height: '10vh',
                    background: `radial-gradient(circle, #034efe88 , #00000000 80%)`,
                    filter: 'blur(10px)',
                    position: 'absolute',
                    top: '0',

                }} />

            </Box>
        </Box>
    )
}

export default EventsBackground
import { Box, Typography } from "@mui/material";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";

const ThumbnailProjectCard = ({
  projectImageSrc,
  projectName,
  onClick,
  NoBR,
  CategoryName,
  sx
}: // deleteProject,
  {
    projectImageSrc: string;
    projectName: string;
    onClick?: () => void;
    NoBR?: boolean;
    CategoryName?: string;
    sx?: any
    // deleteProject: any;
  }) => {
  let gradientColor = '';
  if (CategoryName === 'Live Streaming') {
    gradientColor = 'linear-gradient(to top, #fe034e, #034efe00 80%)';
  } else if (CategoryName === 'Events') {
    gradientColor = 'linear-gradient(to top, #03236e, #034efe00 80%)';
  } else if (CategoryName === 'Sports') {
    gradientColor = 'linear-gradient(to top, green, #034efe00 80%)';
  } else {
    gradientColor = 'linear-gradient(to top, #000, #034efe00 80%)';
  }
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useEffect(() => {
    if (!projectImageSrc.includes("https")) {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      const s3 = new AWS.S3();
      const bucketName = process.env.REACT_APP_S3_BUCKET;
      if (projectImageSrc !== null) {
        s3.getSignedUrl(
          "getObject",
          { Bucket: bucketName, Key: projectImageSrc, Expires: 60 },
          (err, url) => {
            if (err) {
              console.error("Error generating signed URL:", err);
              return;
            }
            setImageUrl(url);
          }
        );
      }
    }
  }, []);

  return (
    <Box
      data-aos="fade-up"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: "100%",
        aspectRatio: "1 / 1",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00000080",
        backdropFilter: "blur(10px)",
        position: "relative",
        overflow: "hidden",
        transform: "scale(1)",
        transition: "0.3s ease",
        cursor: "pointer",
        "&:hover": {
          // transform: "scale(1.05)",
        },
        borderRadius: NoBR ? 0 : "5px",
        ...sx
      }}
    >
      <Box
        component="img"
        src={
          imageUrl ? imageUrl : "https://i.postimg.cc/QtB5JtTk/Untitled-1.jpg"
        }
        sx={{ maxWidth: "auto", maxHeight: "100%", borderRadius: NoBR ? 0 : "5px" }}
      ></Box>

      <Box
        sx={{
          position: "absolute",
          width: "105%",
          minHeight: "100%",
          background: gradientColor,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          filter: "opacity(0)",
          transition: "0.3s ease-in-out",
          "&:hover": {
            filter: "opacity(1)",
          },
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src="https://i.postimg.cc/gjXTHBJ9/logo-stroke.png"
          sx={{
            maxWidth: "80%", maxHeight: "80%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
          }}
          loading="lazy"
        />
        <Typography
          sx={{
            color: "white", padding: "1rem", textAlign: "center"
          }}
        >
          {projectName}
        </Typography>
      </Box>
    </Box >
  );
};

export default ThumbnailProjectCard;

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import StaticBG from "../../../components/BG/StaticBG";
import NavBar from "../../../components/NavBar/NavBar";
import PageHeader from "../../../components/PageHeader";
import AddMemberForm from "./components/AddMemberForm";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { API_URL } from "../../../data/API";

const EditTeamMember = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/member/${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error: any) {
        setError(error.message || "An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <NavBar />
      {/* <BG /> */}
      <StaticBG />
      <Box sx={{ width: "95%", margin: "2rem auto 1rem" }}>
        <PageHeader Header="Add Team Member" />
        <Box sx={{ width: "80%", margin: "0 auto" }}>
          <AddMemberForm
            edit
            memberID={data.member.id}
            init={{
              name: data.member.name,
              role: data.member.role,
              teamName: data.member.teamName,
              description: data.member.description,
              pictureUrl: data.member.pictureUrl,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default EditTeamMember;

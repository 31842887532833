import React from 'react'
import Swipper from '../../../../../components/Swipper'
import { Box } from '@mui/material'

const ImgsSwipper = ({ imgs }: { imgs: string[] }) => {

    return (
        <Swipper NumOfElements={1} timeOut={3000} className='imgs' length={imgs.length} mode='light'>
            {imgs.map((img: string, idx: number) => (
                <Box key={idx} sx={{
                    width: { lg: '45vw', md: '45vw', sm: '90vw', xs: '90vw' },
                    // padding: '1rem ',
                    // boxSizing: 'border-box',
                    aspectRatio: '1920 / 1080',

                    overflow: 'hidden'
                }}>
                    <Box component='img' src={img} sx={{
                        height: { lg: '100%', md: 'auto', sm: 'auto', xs: 'auto' },
                        width: { lg: 'auto', md: '100%', sm: '100%', xs: '100%' },
                    }} />
                </Box>
            ))
            }
        </Swipper >

    )
}

export default ImgsSwipper
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Dialog from "../Dialog";
import AddText from "./AddText";
import AddImages from "./AddImages";
import AddVideo from "./AddVideo";

const SectionBtn = ({
  btnName,
  btnIcon,
  mid,
  right,
  left,
  projectID,
  getData,
  onClick,
}: {
  btnName: string;
  btnIcon: any;
  mid?: boolean;
  right?: boolean;
  left?: boolean;
  projectID: string;
  getData: any;
  onClick: () => void;
}) => {
  const [editDialogShow, setEditDialogShow] = useState<boolean>(false);
  const [clickedBtn, setClickedBtn] = useState<string>("");

  return (
    <>
      <Box
        sx={{
          borderRadius: mid
            ? "0"
            : right
            ? "0px 10px 10px 0px"
            : "10px 0 0 10px",
          backgroundColor: "#666666aa",
          color: "White",
          display: "flex",
          padding: "0.5rem 1rem",
          justifyContent: "center",
          alignItems: "center",
          transition: "0.3s ease-in-out",
          cursor: "pointer",
          userSelect: "none",
          "&:hover": {
            backgroundColor: "#666666",
          },
        }}
        onClick={() => {
          setEditDialogShow(!editDialogShow);
          setClickedBtn(btnName);
          onClick();
        }}
      >
        {btnIcon}
        <Typography sx={{ margin: "0 1rem", fontSize: "0.8rem" }}>
          {btnName}
        </Typography>
      </Box>
      <Dialog show={editDialogShow}>
        {clickedBtn === "Text" ? (
          <AddText projectID={projectID} getData={getData} />
        ) : clickedBtn === "Images" ? (
          <AddImages projectID={projectID} getData={getData} />
        ) : (
          <AddVideo projectID={projectID} getData={getData} />
        )}
      </Dialog>
    </>
  );
};

export default SectionBtn;

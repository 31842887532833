import { Box, Grid } from "@mui/material";
import React from "react";

const VideoSection = ({ videos }: { videos: string[] }) => {
  return (
    <Grid
      container
      spacing={3}
      sx={{ justifyContent: "center" }}
    >
      {videos.map((video: string, idx: number) => (
        <Grid
          key={idx}
          item
          lg={idx === 0 ? 10 : 3}
          md={idx === 0 ? 10 : 4}
          sm={idx === 0 ? 12 : 6}
          xs={idx === 0 ? 12 : 12}
        >
          <iframe
            title="test"
            src={video}
            style={{ width: '100%', aspectRatio: '640 / 360' }}

            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Grid>
      ))}
    </Grid>
  );
};

export default VideoSection;

import { Box } from "@mui/material";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";

const ImageOnlyCard = ({ UUID }: { UUID: string }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_S3_BUCKET;
    if (UUID !== null) {
      s3.getSignedUrl(
        "getObject",
        { Bucket: bucketName, Key: UUID, Expires: 60 },
        (err, url) => {
          if (err) {
            console.error("Error generating signed URL:", err);
            return;
          }
          setImageUrl(url);
          console.log(url)
        }
      );
    }
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        backgroundColor: "#00000080",
        backdropFilter: "blur(10px)",
        position: "relative",
        transform: "scale(1)",
        transition: "0.3s ease",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.05)",
        },
        borderRadius: "5px",
      }}
    >
      <Box
        component="img"
        src={imageUrl ? imageUrl : ""}
        sx={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "5px" }}
      />
    </Box>
  );
};

export default ImageOnlyCard;

export const Paths = {
  admin: {
    home: "/admin/home",
    projects: "/admin/projects",
    team: "/admin/team",
    teamMember: "/admin/team-member",
  },
  employee: {
    home: "/",
    projects: "/projects",
    team: "/team",
    teamMember: "/employee/profile",
  },
  general: {
    home: "/",
    projects: "/projects",
    team: "/team",
    teamMember: "team-member",
  },
};

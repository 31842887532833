import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CategoryHeader from "./CategoryHeader";
import AWS from "aws-sdk";
import ThumbnailProjectCard from "../../../projects/components/Cards/ThumbnailProjectCard";
import Services from "../Services/Services";
import ImgsSwipper from "../img-swipper/ImgsSwipper";
import ImgList from "../img-list/ImgList";
import CircularRepeatText from "../../../../../components/CircularRepeatText";
import NeedsAuth from "../../../../../components/NeedsAuth";
import AddCategory from "./AddCategory";
import ScrollableImageCard from "../../../../../components/ScrollableImageCard";
import ScrollableCardChild from "../live/cards/ScrollableCardChild";
import LinkCard from "../../../projects/components/Cards/LinkCard";
import CategoryBackground from "./backgrounds/CategoryBackground";

const CategoryCard = ({ Category }: { Category: any }) => {

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();
    const bucketName = process.env.REACT_APP_S3_BUCKET;
    if (Category.pictureUrl !== null) {
      if (Category.pictureUrl.includes("https")) {
        setImageUrl(Category.pictureUrl);
      } else {
        s3.getSignedUrl(
          "getObject",
          { Bucket: bucketName, Key: Category.pictureUrl, Expires: 60 },
          (err, url) => {
            if (err) {
              console.error("Error generating signed URL:", err);
              return;
            }
            setImageUrl(url);
          }
        );
      }
    }
  }, []);

  const sportsImgs: { swipperImgs: string[], scrollImgs: string[], services: string[] } = {
    swipperImgs:
      [
        "https://i.postimg.cc/sgqZR2c4/162A0484.jpg",
        "https://i.postimg.cc/3RsTKPq6/tarek-9570.jpg",
        "https://i.postimg.cc/B6DzCthY/tarek-1457.jpg",

      ],
    scrollImgs:
      [
        "https://i.postimg.cc/3RsTKPq6/tarek-9570.jpg",
        "https://i.postimg.cc/B6DzCthY/tarek-1457.jpg",
        'https://i.postimg.cc/htJR38kn/0.jpg',
        'https://i.postimg.cc/25hRCkjX/1.jpg',
        'https://i.postimg.cc/RhP2gqGq/2.jpg',
        'https://i.postimg.cc/63fkGMvr/3X4A4418.jpg',
        'https://i.postimg.cc/fyW67Yc6/3.jpg',
        'https://i.postimg.cc/4dGCdDm4/4.jpg',
        'https://i.postimg.cc/63fkGMvr/3X4A4418.jpg',
        "https://i.postimg.cc/sgqZR2c4/162A0484.jpg",

      ],
    services: ['Videography', 'photography', 'Drone', 'Live Streaming']
  }
  const categorySelector = (name: string) => {
    switch (name) {
      case 'sports':
        return sportsImgs
      case 'events':
        return sportsImgs
      case 'drone':
        return sportsImgs
      case 'live':
        return sportsImgs

      default:
        return sportsImgs

    }
  }

  const projects: string[] = ['Events', 'Sports']
  const services: string[] = ['Events', 'Sports']
  const swipperImgs: string[] = ['Events', 'Sports']
  const scrollImgs: string[] = ['Events', 'Sports']
  const Live: string[] = ['Live Streaming']


  return (
    <Box sx={{ display: "flex", flexDirection: "column", margin: "1rem auto", zIndex: '1' }} data-aos="fade-up">
      <CategoryBackground CategoryName={Category.name} />
      <Box
        sx={{
          // height: { lg: "23rem", md: "23rem", sm: "25vh", xs: "25vh" },
          width: "100%",
          margin: "0 auto",
        }}
      >
        {/* <Typography sx={{
          fontSize: {
            lg: "3rem",
            md: "3rem",
            sm: "3rem",
            xs: "3rem",
          },
          color: "white",
          textAlign: "center",
          fontWeight: '900'
        }}>
          {Category.description}
        </Typography> */}
        {/* <CategoryHeader
          projectImageSrc={
            imageUrl ? imageUrl : "https://i.postimg.cc/QtB5JtTk/Untitled-1.jpg"
          }
          projectName={Category.name}
          id={Category.id}
          description={Category.description}
          init={{
            name: Category.name,
            description: Category.description,
            pictureUrl: Category.pictureUrl,
          }}
        /> */}
      </Box>
      <Box sx={{ marginTop: "1rem", position: "relative" }}>
        <Grid
          container
          sx={{ justifyContent: "flex-start" }}
          columns={18}
        >
          {Category.projects.map((project: any) => (
            <Grid item lg={4} md={4} sm={9} xs={18}>
              <Link
                to={`/projects/${project.id}`}
                style={{ textDecoration: "none" }}
              >
                <ThumbnailProjectCard
                  projectImageSrc={
                    project.pictureUrl !== null
                      ? project.pictureUrl
                      : "https://i.postimg.cc/QtB5JtTk/Untitled-1.jpg"
                  }
                  projectName={project.name}
                  NoBR
                  CategoryName={Category.name}
                />
              </Link>
            </Grid>
          ))}
          {projects.includes(Category.name) ? <Grid item lg={2} md={2} sm={18} xs={18} data-aos="fade-up">
            <Link to={`/categories/${Category.id}` }
                      style={{ textDecoration: "none" }}

            >

              <Box sx={{
                backgroundColor: "#1f1f1f90",
                padding: "3rem 2rem",
                boxSizing: "border-box",
                backdropFilter: "blur(5px)",
                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                color: 'white',
                fontSize: "1.5rem",
                fontWeight: "bold",
                userSelect: 'none',
                cursor: 'pointer',

              }}>
                <Box component='img' src='https://api.vitetime.co/uploads/402107790_STATIC_NOISE_400_d4620d9ade.gif' sx={{
                  position: 'absolute',
                  bottom: "0",
                  right: "0",
                  // height: "80%",
                  zIndex: '-1',
                  filter: "opacity(0.4)",
                  transition: '0.3s',
                  cursor: "pointer",
                  userSelect: 'none',
                  width: '100%',
                  height: '100%',
                  "&:hover": {
                    filter: "opacity(0.2)",

                  }

                }} />
                View More
              </Box>
            </Link>
          </Grid> : null}
          {services.includes(Category.name) ?
            <Grid item lg={18} md={18} sm={18} xs={18} sx={{ marginTop: '0.5rem' }}>
              <Services categoryServices={categorySelector(Category.name).services} />
            </Grid>
            : null}
          {swipperImgs.includes(Category.name) ?
            <Grid item lg={9} md={9} sm={18} xs={18} sx={{ marginTop: '0.5rem' }} data-aos="fade-up">
              <ImgsSwipper imgs={categorySelector(Category.name).swipperImgs} />
            </Grid>
            : null}
          {scrollImgs.includes(Category.name) ?
            <Grid item lg={9} md={9} sm={18} xs={18} sx={{ marginTop: '0.5rem' }} data-aos="fade-up">
              <ImgList imgs={categorySelector(Category.name).scrollImgs} />
            </Grid>
            : null}

          {Live.includes(Category.name) ?
            <Grid item lg={18} md={18} sm={18} xs={18} sx={{ marginTop: '0.5rem' }} data-aos="fade-up">
              <ScrollableImageCard data={[
                {
                  img: "https://i.postimg.cc/QM3YvJD7/4-copy-32.png",
                  children: <ScrollableCardChild title="Director" bio="Meet our Live Stream Director, your guide to seamless live events! With a keen eye for detail and a passion for perfection, they ensure every moment is captured and broadcasted flawlessly, leaving your audience in awe." />
                },
                {
                  img: "https://i.postimg.cc/jj0BtrHL/4-copy-3.png",
                  children: <ScrollableCardChild title="Directing Unit" bio="Our Directing Unit is the heartbeat of your live stream, orchestrating every shot with precision and creativity. From transitions to overlays, they weave together a captivating visual narrative that keeps viewers engaged and immersed." />
                },
                {
                  img: "https://i.postimg.cc/ZKLLCzzW/cameras.jpg",
                  children: <ScrollableCardChild title="1 - 4 Cameras" bio="Unleash the power of multiple perspectives with our 1-4 camera setups! From wide-angle shots to close-ups that capture every emotion, our cameras bring your event to life in vivid detail, ensuring no moment goes unnoticed." />
                },
                {
                  img: "https://i.postimg.cc/kGphZrMM/Artboard-3.png",
                  children: <ScrollableCardChild title="Needed Equipment" bio="Behind every great live stream is the right equipment. From high-definition cameras to professional audio gear, our arsenal of cutting-edge technology ensures your event is seen and heard with crystal clarity, making every second unforgettable." />
                },
              ]} />
            </Grid>
            : null}
          {Live.includes(Category.name) ?

            <Box sx={{
              width: '90vw',
              position: 'relative',
              left: 'calc(-44vw + 50%)',
              mt: 4
            }}>

              <Grid container spacing={3} data-aos="fade-up" sx={{
                width: '90vw',
              }}>
                <Grid item lg={4} md={4} sm={6} xs={12} sx={{ marginTop: '0.5rem' }} data-aos="fade-up">
                  <LinkCard link="https://www.facebook.com/EDLF.Cairozone/videos/608559120656006" imgSrc="https://i.postimg.cc/QCGRxZG6/0.jpg" title="CDLZ Winter 2023" />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12} sx={{ marginTop: '0.5rem' }} data-aos="fade-up">
                  <LinkCard link="https://www.facebook.com/EGParalympics/videos/1155048859140178" imgSrc="https://i.postimg.cc/7ZhDRDx7/1.jpg" title="Paralympic Power Lifting 2024" />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12} sx={{ marginTop: '0.5rem' }} data-aos="fade-up">
                  <LinkCard link="https://www.facebook.com/100057585933868/videos/6907883699262756" imgSrc="https://i.postimg.cc/kMs0PZYy/2.jpg" title="حفل تخرج هندسة القاهرة 2023" />
                </Grid>
              </Grid>
            </Box>
            : null}

        </Grid>
      </Box>
      <NeedsAuth Allowed={["admin"]}>
        <AddCategory />
      </NeedsAuth>
    </Box >
  );
};

export default CategoryCard;

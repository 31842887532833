import React from 'react'
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import CameraRoundedIcon from '@mui/icons-material/CameraRounded';
import ServiceCard from './ServiceCard';
import { Grid } from '@mui/material';
import LiveStreamingIcon from './icons/LiveStreamingIcon';
import DroneIcon from './icons/DroneIcon';
const Services = ({ categoryServices }: { categoryServices: string[] }) => {

    const Services: { name: string, icon: any, sx?: any }[] = [
        { name: 'Videography', icon: <VideocamRoundedIcon sx={{ fontSize: 'inherit' }} />, sx: { backgroundColor: '#c1272d', color: 'white' } },
        { name: 'photography', icon: <CameraRoundedIcon sx={{ fontSize: 'inherit' }} />, sx: { backgroundColor: '#2e3192', color: 'white' } },
        { name: 'Drone', icon: <DroneIcon />, sx: { backgroundColor: '#187c62', color: 'white' } },
        { name: 'Live Streaming', icon: <LiveStreamingIcon />, sx: { backgroundColor: 'white', color: '#fe034e' } },
    ]

    const filterServices = (filterArray: string[]) => {
        return Services.filter(service => filterArray.includes(service.name));
    };

    return (
        <>
            <Grid container spacing={1}>
                {filterServices(categoryServices).map((Service: { name: string, icon: any, sx?: any }, idx: number) => (
                    <Grid item lg={3} md={3} sm={6} xs={12} key={idx} data-aos="fade-up"  data-aos-delay={`${idx * 200}`} >
                        <ServiceCard Service={Service} />
                    </Grid>
                ))}

            </Grid>
        </>
    )
}

export default Services
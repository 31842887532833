import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

const Loading = () => {
  const [logoSize, setLogoSize] = useState<boolean>(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLogoSize(!logoSize);
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [logoSize]);
  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000000bb",
        zIndex: 1000,
        top: "0",
        right: "0",
      }}
    >
      <Box
        component="img"
        src="https://i.postimg.cc/brmRcPLT/logo.png"
        alt=""
        sx={{
          width: "8rem",
          display: { xs: "none", md: "flex" },
          mr: 1,
          transition: "0.5s ease-in-out",
          transform: `${logoSize ? "scale(1.2)" : "scale(1)"}`,
        }}
      ></Box>
    </Box>
  );
};

export default Loading;
